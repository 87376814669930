import TokenItem from "../TokenItem/TokenItem";
import Title from "../../../ui/Title";
import { useEffect, useState } from "react";
import { PreloaderCircle } from "../../../ui/PreloaderCircle";
import { useParams } from "react-router-dom";
import { ResponseTokensListData, TokensByUserParams } from "../../../../api/tokens/types";
import { getTokensByUser } from "../../../../api/tokens/getTokensByUser";

const PAGE_SIZE = 2;

const CreatedTokensListByUser = () => {
  const [tokens, setTokens] = useState<ResponseTokensListData | null>(null);
  const [page, setPage] = useState<number>(1);
  const [isPending, setIsPending] = useState<boolean>(false);

  const { userId } = useParams();

  const fetchTokenList = async () => {
    setIsPending(true);
    const params: TokensByUserParams = {
      userId: +userId!,
      page: page,
      pageSize: PAGE_SIZE,
    };

    const result = await getTokensByUser(params);
    setTokens((prevTokens) => {
      if (!prevTokens) return result;

      return {
        ...prevTokens,
        items: [...prevTokens.items, ...result.items],
        total: result.total,
      };
    });

    setIsPending(false);
  };

  useEffect(() => {
    fetchTokenList();
  }, [page]);

  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      {tokens && (
        <div className="pt-8">
          <Title text="Created Tokens" />
          <div className="flex flex-col gap-2">
            {tokens.items?.map((token) => <TokenItem key={token.id} token={token} />)}
          </div>
          {tokens.total > tokens.items.length && (
            <button
              disabled={isPending}
              className="font-semibold flex justify-center items-center  gap-x-5 text-base h-[64px] text-white w-full text-center py-5"
              onClick={nextPage}
            >
              <span className="relative">
                Load more tokens
                {isPending ? (
                  <span className="flex absolute right-0 translate-x-[calc(100%+12px)] top-1/2 -translate-y-1/2 justify-center text-[6px] items-center text-orange">
                    <PreloaderCircle />
                  </span>
                ) : null}
              </span>
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default CreatedTokensListByUser;
