import { useSelector } from "react-redux";
import { BottomSheet } from "../../../ui/BottomSheet";
import Button from "../../../ui/Button";
import {
  changeReloadCandleDataStatus,
  currentCountTokensAndPriceSelector,
  currentCountTokensForSellSelector,
  currentTokenPriceDataSelector,
  currentTokenSelector,
  socketConnectSelector,
} from "../../../../store/token/tokensReducer";
import { ExternalImage } from "../../../ui/ExternalImage";
import { useTransaction } from "../../../../hooks/useTransaction";
import { useAppDispatch } from "../../../../store/hooks";
import { useCallback, useEffect, useState } from "react";
import { fetchCountTokensAndPriceAction } from "../../../../store/token/actions/fetchCountTokensAndPriceAction";
import { TradeType } from "./FilterTrade";
import { fetchCountTokensForSellAction } from "../../../../store/token/actions/fetchCountTokensForSellAction";
import { GetCountTokensAndPriceBody } from "../../../../api/tokens/types";
import { SaveTransactionData } from "../../../../api/transactions/types";
import { saveDataForTransaction } from "../../../../api/transactions/saveDataForTransaction";
import { useToastNotifications } from "../../../../hooks/useToastNotifications";
import { ToastNotificationType } from "../../../../store/toastNotifications/toastNotificationsReducer";
import { useParams } from "react-router-dom";
import { fetchTokenInfoAction } from "../../../../store/token/actions/fetchTokenInfoAction";

interface ConfirmPurchaseProps {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
  inputAmount: number;
  activeTradeType: TradeType;
}

const ConfirmPurchase = ({ isOpened, setIsOpened, inputAmount, activeTradeType }: ConfirmPurchaseProps) => {
  const [isPending, setIsPending] = useState(false);
  const { tokenId } = useParams();

  const token = useSelector(currentTokenSelector);
  const currentTokenPriceData = useSelector(currentTokenPriceDataSelector);
  const currentCountTokensAndPrice = useSelector(currentCountTokensAndPriceSelector);
  const currentCountTokensForSell = useSelector(currentCountTokensForSellSelector);

  const { buyTokenTransaction, sellTokenTransaction } = useTransaction();
  const [valueSend, setValueSend] = useState<number | string>(0);
  const [valueReceive, setValueReceive] = useState<number | string>(0);

  const { showNotification } = useToastNotifications();
  const socket = useSelector(socketConnectSelector);
  const { name, ticker, logo } = token!;

  const messageSend = activeTradeType === TradeType.BUY ? `Send SOL` : `Send $${ticker}`;
  const messageReceive = activeTradeType === TradeType.BUY ? `Receive ${ticker}` : `Receive SOL`;

  const dispatch = useAppDispatch();
  useEffect(() => {
    setValueSend(activeTradeType === TradeType.BUY ? currentCountTokensAndPrice?.sumForPay || 0 : inputAmount || 0);

    setValueReceive(
      activeTradeType === TradeType.BUY
        ? currentCountTokensAndPrice?.tokensToRelease || 0
        : currentCountTokensForSell?.totalSol || 0,
    );
  }, [activeTradeType, currentCountTokensAndPrice, currentCountTokensForSell, inputAmount]);

  useEffect(() => {
    if (!socket) return;

    const handleTokenUpdated = async (update: { tokenId: number }) => {
      if (update.tokenId === token?.id) {
        console.log("Old currentTokenPriceData:", currentTokenPriceData);

        try {
          // Получаем обновленные данные из fetchTokenInfoAction
          const updatedTokenData = await dispatch(fetchTokenInfoAction(tokenId!)).unwrap();
          console.log("Updated token data:", updatedTokenData);

          const data: GetCountTokensAndPriceBody = {
            priceWithFee: updatedTokenData.tokenPriceData?.priceWithFee || 0,
            priceWithoutFee: updatedTokenData.tokenPriceData?.priceWithoutFee || 0,
            amount: inputAmount || 0,
            mintAddress: token.mintAddress,
            myFee: updatedTokenData.tokenPriceData?.fee || 0,
          };

          const tokenSendPrice = await dispatch(fetchCountTokensAndPriceAction(data)).unwrap();
          const tokenForSall = await dispatch(fetchCountTokensForSellAction(data)).unwrap();

          setValueSend(activeTradeType === TradeType.BUY ? tokenSendPrice?.sumForPay || 0 : inputAmount || 0);
          setValueReceive(
            activeTradeType === TradeType.BUY ? tokenSendPrice?.tokensToRelease || 0 : tokenForSall?.totalSol || 0,
          );
        } catch (error) {
          console.error("Error updating token data:", error);
        }
      }
    };

    socket.on("token-updated", handleTokenUpdated);

    return () => {
      socket.off("token-updated", handleTokenUpdated);
    };
  }, [socket, token, inputAmount, activeTradeType]);

  const getActualCountToken = useCallback(
    (value: number | null = null) => {
      if (!token) return;

      const amount = value ? value : +inputAmount;

      const data: GetCountTokensAndPriceBody = {
        priceWithFee: currentTokenPriceData?.priceWithFee || 0,
        priceWithoutFee: currentTokenPriceData?.priceWithoutFee || 0,
        amount: amount || 0,
        mintAddress: token.mintAddress,
        myFee: currentTokenPriceData?.fee || 0,
      };

      if (activeTradeType === TradeType.BUY) {
        dispatch(fetchCountTokensAndPriceAction(data));
      }
      if (activeTradeType === TradeType.SELL) {
        dispatch(fetchCountTokensForSellAction(data));
      }
    },
    [inputAmount, currentTokenPriceData, activeTradeType],
  );

  const saveTransactionData = async (): Promise<any> => {
    const tokensToRelease =
      activeTradeType === TradeType.BUY ? currentCountTokensAndPrice?.tokensToRelease : inputAmount;

    const amountSol = activeTradeType === TradeType.BUY ? inputAmount : currentCountTokensForSell?.totalSol;
    const interestOnSale = activeTradeType === TradeType.BUY ? currentCountTokensAndPrice?.interestOnSale : 0;

    const saveDataTransaction: SaveTransactionData = {
      amount: amountSol || 0,
      mintAddress: token?.mintAddress!,
      tokenId: token?.id!,
      tokenAccount: token?.mintAccount!,
      tokensToRelease: tokensToRelease!,
      myFee: currentTokenPriceData?.fee!,
      interestOnSale: interestOnSale!,
      tokenPrice: currentTokenPriceData?.priceWithoutFee!,
    };
    return await saveDataForTransaction(saveDataTransaction);
  };

  const buyToken = async () => {
    try {
      getActualCountToken();

      await saveTransactionData();

      await new Promise((resolve) => setTimeout(resolve, 2000));

      try {
        await buyTokenTransaction();
        console.log("Transaction successfully completed");
      } catch (err) {
        console.error("Error signing and sending transaction:", err);
        showNotification({
          title: "Transaction failed. Please, try again later!",
          type: ToastNotificationType.Error,
          timeoutMs: 2000,
        });
      } finally {
        setIsPending(false);
        dispatch(changeReloadCandleDataStatus(true));
      }
    } catch (error) {
      console.error("Error in transaction process:", error);
      showNotification({
        title: "Something went wrong! Please, try again later!",
        type: ToastNotificationType.Error,
        timeoutMs: 2000,
      });
      setIsPending(false);
      dispatch(changeReloadCandleDataStatus(true));
    }
  };

  const sellToken = async () => {
    try {
      getActualCountToken();

      await saveTransactionData();

      await new Promise((resolve) => setTimeout(resolve, 2000));
      try {
        await sellTokenTransaction(inputAmount);
        console.log("Transaction successfully completed");
      } catch (err) {
        console.error("Error signing and sending transaction:", err);
        showNotification({
          title: "Transaction failed. Please, try again later!",
          type: ToastNotificationType.Error,
          timeoutMs: 2000,
        });
      } finally {
        setIsPending(false);
        dispatch(changeReloadCandleDataStatus(true));
      }

      console.log("Transaction signed and sent");
    } catch (err) {
      console.error("Error signing and sending transaction:", err);
      showNotification({
        title: "Something went wrong! Please, try again later!",
        type: ToastNotificationType.Error,
        timeoutMs: 2000,
      });
    } finally {
      setIsPending(false);
      dispatch(changeReloadCandleDataStatus(true));
    }
  };

  const handleConfirm = async () => {
    setIsPending(true);
    if (activeTradeType === TradeType.BUY) buyToken();
    if (activeTradeType === TradeType.SELL) sellToken();
  };

  useEffect(() => {
    // Логика, которая должна срабатывать при обновлении valueSend или valueReceive
    console.log("Values updated:", { valueSend, valueReceive, currentCountTokensAndPrice, currentCountTokensForSell });
  }, [valueSend, valueReceive]);

  useEffect(() => {
    getActualCountToken();
  }, [isOpened, valueSend, valueReceive]);

  const shareSupply =
    activeTradeType === TradeType.BUY
      ? currentCountTokensAndPrice?.fixPersenteg
      : currentCountTokensForSell?.fixPersenteg;
  return (
    <BottomSheet
      isOpened={isOpened}
      onClose={() => setIsOpened(false)}
      title="Confirm purchase"
      classNameForChildrenContainer="px-4 py-4"
    >
      <div className="mt-3 flex flex-col gap-2 text-[14px]">
        <div className="rounded-2xl bg-mediumGrey/25 px-2 pt-[10px] pb-4 ">
          <div className="flex justify-between px-2   border-solid border-b-[1px] border-white/10 pb-3">
            <p className=" font-semibold text-lavenderGray">Name</p>
            <div className="font-bold flex gap-2">
              <ExternalImage loading="lazy" src={logo} alt="token-image" className="size-5 object-cover" />
              <p>{name}</p>
            </div>
          </div>
          <div className="flex justify-between pt-3 px-2 ">
            <p className="font-semibold text-lavenderGray">Ticker</p>
            <div className="font-bold">
              <p>${ticker}</p>
            </div>
          </div>
        </div>

        <div className="rounded-2xl bg-mediumGrey/25 px-2 pt-[10px] pb-4 mb-[14px]">
          <div className="flex justify-between  px-2  border-solid border-b-[1px] border-white/10 pb-3">
            <p className=" font-semibold text-lavenderGray">{messageSend}</p>
            <div className=" font-bold flex gap-2 items-center">
              {/* Условный рендеринг иконки в зависимости от activeTradeType */}
              {activeTradeType === TradeType.BUY ? (
                <img src="/solana-sol-logo.svg" className="size-4 object-cover" alt="solana-icon" />
              ) : (
                <ExternalImage loading="lazy" src={logo} className="size-5 object-cover" alt="token-image" />
              )}
              <p>{valueSend}</p>
            </div>
          </div>
          <div className="flex justify-between px-2  py-3 border-solid border-b-[1px] border-white/10">
            <p className=" font-semibold text-lavenderGray">{messageReceive}</p>
            <div className=" font-bold flex gap-2">
              {/* Условный рендеринг иконки в зависимости от activeTradeType */}
              {activeTradeType === TradeType.BUY ? (
                <ExternalImage loading="lazy" src={logo} className="size-5 object-cover" alt="token-image" />
              ) : (
                <img src="/solana-sol-logo.svg" className="size-4 object-cover" alt="solana-icon" />
              )}
              <p>{valueReceive}</p>
            </div>
          </div>
          <div className="flex justify-between pt-3 px-2  ">
            <p className="font-semibold text-lavenderGray">Share of the supply</p>
            <div className=" font-bold">
              <p>{shareSupply}%</p>
            </div>
          </div>
        </div>

        <p className="text-center pb-2 font-medium">
          <span className="pr-2 text-lavenderGray">Transaction fee:</span>
          <span>{currentTokenPriceData?.fee!} SOL</span>
        </p>
        <Button
          text="Confirm"
          isPending={isPending}
          handleClick={handleConfirm}
          style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
        />
      </div>
    </BottomSheet>
  );
};

export default ConfirmPurchase;
