import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { fetchIsCurrentTokenExist, fetchTokenInfoAction, fetchTokenProgress } from "../../store/token/actions/fetchTokenInfoAction";
import TokenHeader from "../../components/Pumpadur/Token/TokenTrade/TokenHeader";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import TokenChart from "../../components/Pumpadur/Token/TokenTrade/TokenChart";
import TradeActions from "../../components/Pumpadur/Token/TokenTrade/TradeActions";
import { useSelector } from "react-redux";
import {
  changeReloadCandleDataStatus,
  clearCurrentToken,
  isCurrentTokenExistSelector,
  isPendingSelector,
  setSocket,
} from "../../store/token/tokensReducer";
import TransactionSummary from "../../components/Pumpadur/Token/TokenTrade/Transaction/TransactionSummary";
import TransactionList from "../../components/Pumpadur/Token/TokenTrade/Transaction/TransactionList";
import LastNote from "../../components/Pumpadur/Notes/LastNote";
import { fetchLastNoteForTokenAction } from "../../store/token/actions/fetchLastNoteForTokenAction";
import scrollLock from "scroll-lock";
import { connectToSocket } from "../../helpers/connectSocket";
import { fetchCryptoProfileAction } from "../../store/cryptoProfile/actions/fetchCryptoProfileAction";

const TokenTradePage = () => {
  const { tokenId } = useParams();

  const dispatch = useAppDispatch();
  const isPending = useSelector(isPendingSelector);
  const isTokenExist = useSelector(isCurrentTokenExistSelector);

  useEffect(() => {
    const setupSocket = async () => {
      const socket = await connectToSocket();
      dispatch(setSocket(socket));
    };
    setupSocket();

    return () => {
      dispatch(setSocket(null));
    };
  }, [dispatch]);

  
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(fetchCryptoProfileAction());
    }, 1000);
  
    return () => clearTimeout(timeout);
  }, [dispatch]);

  // Загрузка начальных данных
  useEffect(() => {
    scrollLock.enablePageScroll();
    dispatch(changeReloadCandleDataStatus(true));
    dispatch(changeStatusBottomMenu(false));
    dispatch(fetchTokenInfoAction(tokenId!));
    dispatch(fetchIsCurrentTokenExist(tokenId!));
    dispatch(fetchTokenProgress(tokenId!));
    dispatch(fetchLastNoteForTokenAction(tokenId!));
    return () => {
      dispatch(changeStatusBottomMenu(true));
      dispatch(clearCurrentToken());
    };
  }, [tokenId]);
  
  

  // Обновление цены токена каждые 5 секунд
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchLastNoteForTokenAction(tokenId!));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [tokenId, dispatch]);

  return (
    <>
      {!isPending && isTokenExist && (
        <div className="pb-5">
          <TokenHeader />
          <TokenChart />
          <TradeActions />
          <TransactionSummary />
          <div className="pt-[4vh]">
            <LastNote />
          </div>
          <TransactionList />
        </div>
      )}
    </>
  );
};

export default TokenTradePage;
