import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ResponseTokensList, SearchTokensParams } from "./types.ts";

export async function getSearchTokens(params: SearchTokensParams) {
  const response = await axiosInstance.get<ResponseTokensList>(apiRoutes.tokens.searchTokens, {
    params,
  });
  return response.data.data;
}
