import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GlobalState {
  overlay: boolean;
  visualViewportSize: [number, number] | undefined;
}

const initialState: GlobalState = {
  overlay: false,
  visualViewportSize: undefined,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    isVisibleOverlay: (state, action: PayloadAction<boolean>) => {
      state.overlay = action.payload;
    },
    changeVisualViewportSize: (state, action: PayloadAction<[number, number]>) => {
      state.visualViewportSize = action.payload;
    },
  },
});

export const { isVisibleOverlay, changeVisualViewportSize } = globalSlice.actions;

export const visualViewportSizeSelector = (state: { global: GlobalState }) => state.global.visualViewportSize;

export default globalSlice.reducer;
