import { createSlice } from "@reduxjs/toolkit";
import { fetchCryptoProfileAction } from "./actions/fetchCryptoProfileAction";
import { RootState } from "../store";
import { fetchRewardForWalletAction } from "./actions/fetchRewardForWalletAction";
import { CryptoProfileByUser, Nft } from "./types";
import { fetchRewardForNftAction } from "./actions/fetchRewardForNftAction";
import { fetchCryptoProfileByUserAction } from "./actions/fetchCryptoProfileByUserAction";
import { toggleFollowUserAction } from "./actions/toggleFollowUserAction";
import { fetchMyNftsInChainAction } from "./actions/fetchMyNftsInChainAction";
import { CryptoDataCurrentUser } from "../../api/wallet/types";

export interface CryptoProfileState {
  isActiveMyWallet: boolean;
  profileLink: string;
  reward: boolean;
  rewardTickets: number;
  nft: Nft[];
  myCryptoData: CryptoDataCurrentUser | null;
  cryptoDataByUser: CryptoProfileByUser | null;
  pending: boolean;
  error: string | null;
}

const initialState: CryptoProfileState = {
  isActiveMyWallet: false,
  profileLink: "",
  reward: false,
  rewardTickets: 0,
  nft: [],
  myCryptoData: null,
  cryptoDataByUser: null,
  pending: false,
  error: null,
};

const cryptoProfileSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCryptoProfileAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchCryptoProfileAction.fulfilled, (state, action) => {
        state.pending = false;
        if (action.payload) {
          state.myCryptoData = action.payload.data;
          state.reward = !!action.payload.data.updatedCriptoProfile.updatedCriptoProfile.reward;
        }
        state.isActiveMyWallet = action.payload.status;
        if (
          state.myCryptoData &&
          state.myCryptoData.updatedCriptoProfile &&
          action.payload?.data?.updatedCriptoProfile.updatedCriptoProfile.publicKey === "disconnected"
        ) {
          state.isActiveMyWallet = false;
          if (state.myCryptoData.updatedCriptoProfile) {
            state.myCryptoData.updatedCriptoProfile.updatedCriptoProfile.publicKey = "";
          }
        }
        state.error = null;
      });

    //GET MY NFTS
    builder.addCase(fetchMyNftsInChainAction.fulfilled, (state, action) => {
      if (action.payload) {
        state.nft = action.payload.nft;
      }
    });

    builder
      .addCase(fetchRewardForWalletAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchRewardForWalletAction.fulfilled, (state, action) => {
        state.pending = false;
        state.reward = !!action.payload.reward;
        state.rewardTickets = action.payload.ticket;
      });

    //CRYPTO DATA BY USER ID
    builder
      .addCase(fetchCryptoProfileByUserAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchCryptoProfileByUserAction.fulfilled, (state, action) => {
        state.pending = false;

        state.cryptoDataByUser = {
          ...action.payload.data.updatedCriptoProfile,
          ...action.payload.data.followers,
          checkFollow: action.payload.data.checkFollow,
        };
      });

    // FOLLOW/UNFOLLOW BY USER
    builder
      .addCase(toggleFollowUserAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(toggleFollowUserAction.fulfilled, (state, action) => {
        state.pending = false;
        state.cryptoDataByUser!.followersCount = action.payload.followersCount;
        state.cryptoDataByUser!.followingCount = action.payload.followingCount;
      });

    //claim reward for nft
    builder.addCase(fetchRewardForNftAction.fulfilled, (state, action) => {
      const { mintAddresArr } = action.payload;
      state.nft = mintAddresArr;
    });
  },
});

export default cryptoProfileSlice.reducer;

export const pendingSelector = (state: RootState) => state.cryptoProfile.pending;
export const cryptoProfileBalanceSelector = (state: RootState) =>
  state.cryptoProfile.myCryptoData?.updatedCriptoProfile.updatedCriptoProfile.balance;
export const cryptoDataByUserSelector = (state: RootState) => state.cryptoProfile.cryptoDataByUser;
export const myCryptoDataSelector = (state: RootState) => state.cryptoProfile.myCryptoData;
export const cryptoProfilePublicKeySelector = (state: RootState) =>
  state.cryptoProfile.myCryptoData?.updatedCriptoProfile.updatedCriptoProfile.publicKey;
export const dappPublicKeySelector = (state: RootState) =>
  state.cryptoProfile.myCryptoData?.updatedCriptoProfile.updatedCriptoProfile.dappPublicKey;
export const dappSecretKeySelector = (state: RootState) =>
  state.cryptoProfile.myCryptoData?.updatedCriptoProfile.updatedCriptoProfile.dappSecretKey;
export const sessionKeySelector = (state: RootState) =>
  state.cryptoProfile.myCryptoData?.updatedCriptoProfile.updatedCriptoProfile.sessionKey;
export const sharedSecretSelector = (state: RootState) =>
  state.cryptoProfile.myCryptoData?.updatedCriptoProfile.updatedCriptoProfile.sharedSecret;
export const isActiveWalletSelector = (state: RootState) => state.cryptoProfile.isActiveMyWallet;
export const followersSelector = (state: RootState) => state.cryptoProfile.myCryptoData?.followers;
export const isRewardReceivedSelector = (state: RootState) => state.cryptoProfile.reward;
export const rewardTicketsSelector = (state: RootState) => state.cryptoProfile.rewardTickets;
export const nftSelector = (state: RootState) => state.cryptoProfile.nft;
