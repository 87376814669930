import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyTransactions } from "../../../api/transactions/getMyTransactions";
import { PageParams } from "../../../api/types";

export const fetchMyTransactionListAction = createAsyncThunk(
  "tokens/fetchMyTransactionList",
  async (params: PageParams) => {
    return getMyTransactions(params);
  },
);
