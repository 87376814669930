import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ApiResponse } from "../types.ts";
import { CommunityNotesData, NotesParams } from "./types.ts";

export async function getNotesForToken(params: NotesParams) {
  const response = await axiosInstance.get<ApiResponse<CommunityNotesData>>(apiRoutes.communityNotes.getNotesForToken, {
    params,
  });
  return response.data.data;
}
