import { moneyFormat } from "../../../../helpers/moneyFormat";
import ProgressLine from "../../../Common/ProgressLine";
interface TokenMetricsProps {
  progress: string;
  marcetCap: string;
}

const TokenMetrics = ({ marcetCap, progress }: TokenMetricsProps) => {
  return (
    <div>
      <div className="flex justify-between text-[10px] font-bold pb-[7px]">
        <div>
          Market Cap: <span className="text-green">${moneyFormat(+marcetCap, 0)}</span>
        </div>
        <div>{progress}% to Radium</div>
      </div>
      <ProgressLine progress={+progress} styles="bg-black/50" />
    </div>
  );
};

export default TokenMetrics;
