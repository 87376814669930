import { createAsyncThunk } from "@reduxjs/toolkit";

import { getTokenById } from "../../../api/tokens/getTokenById";

export const fetchTokenInfoAction = createAsyncThunk("tokens/fetchTokenInfoAction", async (id: string) => {
  return getTokenById(id);
});

export const fetchTokenProgress = createAsyncThunk("tokens/fetchTokenProgress", async (id: string) => {
  const data = await getTokenById(id); 
  return { progress: data.token.progress,
    marcetCap: data.token.marcetCap
   };
});

export const fetchIsCurrentTokenExist = createAsyncThunk("tokens/fetchIsCurrentTokenExist", async (id: string) => {
  const data = await getTokenById(id);
  if(data?.token){
    return true
  } 
  return false
});
