import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ApiResponse } from "../types.ts";
import { ResponseTransactionssListData, TransactionsByUserParams } from "./types.ts";

export async function getTransactionsByUserId(params: TransactionsByUserParams) {
  return (
    await axiosInstance.get<ApiResponse<ResponseTransactionssListData>>(apiRoutes.transactions.transactionByUserId, {
      params,
    })
  ).data.data;
}
