import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import { createTransferInstruction } from "@solana/spl-token";

export const sellToken = async (
  connection: Connection,
  fromTokenAccount: PublicKey,
  toTokenAccount: PublicKey,
  userPublicKey: PublicKey,
  amount: number,
) => {
  const amountOfToken = Math.round(amount * 1_000_000_000);
  console.log("fromTokenAccount", fromTokenAccount.toBase58());
  console.log("toTokenAccount", toTokenAccount.toBase58());
  console.log("userPublicKey", userPublicKey.toBase58());
  console.log("amountOfToken", amountOfToken);

  const transaction = new Transaction().add(
    createTransferInstruction(fromTokenAccount, toTokenAccount, userPublicKey, amountOfToken),
  );

  const { blockhash } = await connection.getLatestBlockhash();
  transaction.recentBlockhash = blockhash;
  transaction.feePayer = userPublicKey;
  console.log("transaction", transaction);

  return transaction;
};
