import { useSelector } from "react-redux";
import userPhoto from "../../../../assets/icons/user2.svg";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";
import { ExternalImage } from "../../../ui/ExternalImage";

const CreatedBy = () => {
  const token = useSelector(currentTokenSelector);
  const {
    userName,
    user: { photo_url },
  } = token!;
  return (
    <div className="flex items-center  gap-[10px]">
      <div className=" w-[28px] h-[28px] rounded-[20px] outline outline-offset-[2px] outline-[2px] outline-darkPurple">
        {photo_url ? (
          <ExternalImage src={photo_url} alt={userName} className="rounded-full" />
        ) : (
          <img src={userPhoto} alt="userPhoto" className="rounded-full bg-lightPurple" />
        )}
      </div>
      <p className="font-medium text-lightestPurple underline underline-offset-4">{userName}</p>
    </div>
  );
};

export default CreatedBy;
