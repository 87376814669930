
import { encryptString } from "../helpers/decryptUserData";
import { useTelegramApp } from "./useTelegramApp";
import { useEffect, useState } from "react";

export function useTgChatId() {
  const webApp = useTelegramApp();
  const [chatId, setChatId] = useState<string | null>(null);
  const env = import.meta.env;

  useEffect(() => {
    if (env.VITE_ENVIRONMENT === "local" && env.VITE_TEST_TG_CHAT_ID) {
      const {encryptedData} = encryptString(env.VITE_TEST_TG_CHAT_ID)
      setChatId(encryptedData);
      return;
    }

    if (!webApp.initDataUnsafe.user?.id || !webApp.initData) {
      return;
    }

    const receivedChatId = webApp.initDataUnsafe.user.id;
    // if (!isValidUser) {
    //   setChatId(null);
    //   return;
    // }
    const {encryptedData} = encryptString(receivedChatId.toString())
    setChatId(encryptedData);
  }, []);

  return {
    chatId,
  };
}
