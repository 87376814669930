import { useSelector } from "react-redux";
import TokenHeader from "../../components/Pumpadur/Token/TokenInfo/TokenHeader";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { useParams } from "react-router-dom";
import { clearCurrentToken, currentTokenSelector, isPendingSelector } from "../../store/token/tokensReducer";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import { fetchTokenInfoAction } from "../../store/token/actions/fetchTokenInfoAction";
import SocialMediaLinks from "../../components/Pumpadur/Token/TokenInfo/SocialMediaLinks";
import TokenLink from "../../components/Pumpadur/Token/TokenLink";
import TokenMetrics from "../../components/Pumpadur/Token/TokenInfo/TokenMetrics";
import TokenOverview from "../../components/Pumpadur/Token/TokenInfo/TokenOverview";
import HoldersList from "../../components/Pumpadur/Holders/HoldersList";
import { Holder } from "../../api/holders/types";
import { getHoldersForToken } from "../../api/holders/getHoldersForToken";

const TokenInfoPage = () => {
  const [holders, setHolders] = useState<Holder[]>([]);

  const { tokenId } = useParams();

  const token = useSelector(currentTokenSelector);
  const isPending = useSelector(isPendingSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));

    const fetchHolders = async () => {
      try {
        const data = await getHoldersForToken(+tokenId!);
        setHolders(data);
      } catch (error) {
        console.error("Error fetching holders:", error);
      }
    };

    fetchHolders();
    dispatch(fetchTokenInfoAction(tokenId!));
    return () => {
      dispatch(changeStatusBottomMenu(true));
      dispatch(clearCurrentToken());
    };
  }, []);

  return (
    <>
      {!isPending && token && (
        <div className="pb-5">
          <TokenHeader />
          <SocialMediaLinks />
          <TokenOverview />

          <TokenMetrics />

          <TokenLink />
          <HoldersList holders={holders} />
        </div>
      )}
    </>
  );
};

export default TokenInfoPage;
