import React, { useRef } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | number;
  label?: string | number;
  border?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputWidthLabel: React.FC<InputProps> = React.memo(
  ({ value, className = "", border = false, handleChange, label, ...attrs }) => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const inputRef = useRef<HTMLInputElement>(null);
    const container = document.querySelector(".main-container") as HTMLElement;

    const handleFocus = () => {
      if (isIOS) {
        container.style.paddingBottom = `${window.innerHeight / 2}px`;
        if (inputRef.current) {
          inputRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
        }
      }
    };
    const handleBlur = () => {
      if (isIOS) {
        container.style.paddingBottom = "0px";
        window.scroll(0, 0);
      }
    };

    return (
      <div className={`relative ${border ? "border-solid border-b-[1px] border-[#fff]/10" : ""}`}>
        <input
          ref={inputRef}
          type="text"
          value={value}
          {...attrs}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          className="peer m-0 block h-[56px] w-full rounded bg-transparent bg-clip-padding py-4 text-sm font-semibold leading-tight text-white transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:outline-none peer-focus:text-primary [&:not(:placeholder-shown)]:pb-[0rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
          placeholder=""
        />
        <label className="pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent py-4 text-base text-white/50 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:text-[12px] peer-focus:text-white/50 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-[12px] motion-reduce:transition-none">
          {label}
        </label>
      </div>
    );
  },
);

export default InputWidthLabel;
