import UserWalletInfo from "../UserWalletInfo";
import NftList from "./Nft/NftList";
import NftsConditions from "./Nft/NftsConditions";
import { useTelegramApp } from "../../../hooks/useTelegramApp";
import Button from "../../ui/Button";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { userIdSelector, userNameSelector } from "../../../store/account/account";
import {
  cryptoProfileBalanceSelector,
  cryptoProfilePublicKeySelector,
  isActiveWalletSelector,
} from "../../../store/cryptoProfile/cryptoProfileReducer";
import { getUserName } from "../../../helpers/getUserName";

const MintAndManageNFT = () => {
  const userName = useSelector(userNameSelector);
  const userId = useSelector(userIdSelector);
  const phantomWalletPublicKey = useSelector(cryptoProfilePublicKeySelector);
  const phantomWalletBalance = useSelector(cryptoProfileBalanceSelector);
  const isActiveWallet = useSelector(isActiveWalletSelector);
  const tgApp = useTelegramApp();

  const handleOpenSite = () => {
    tgApp.openLink("https://presale.magiceden.io/pay/66c70691991efe89d4f0fc83");
  };

  return (
    <div className="pt-[22px] pb-[53px]">
      <UserWalletInfo
        showBalance={true}
        phantomWalletBalance={phantomWalletBalance}
        phantomWalletPublicKey={phantomWalletPublicKey!}
        userName={getUserName(userName, userId)}
        isActiveWallet={isActiveWallet}
      />
      <NftList />
      <NftsConditions />
      {ReactDOM.createPortal(
        <div className="fixed z-[50] left-4 right-4 w-[calc(100% - 16px)] bottom-[82px]">
          <Button
            text="BUY NFT ON MAGIC EDEN"
            handleClick={handleOpenSite}
            style="linear-gradient(162deg, #fe3f8f 0%, #e42575 100%)"
          />
        </div>,
        document.body,
      )}
    </div>
  );
};

export default MintAndManageNFT;
