interface AppBgProps {
  position?: "fixed" | "absolute";
}

export function AppBg({ position = "fixed" }: AppBgProps) {
  return (
    <>
      <div
        className={`${position} top-[23%] left-1/2 transform -translate-x-1/2  w-[390%] h-[226%] rounded-[50%] z-0 opacity-50`}
        style={{
          background: "linear-gradient(180deg, rgba(167, 109, 203, 0.53) 0%, #7724AA 50%)",
          filter: "blur(120px)",
        }}
      ></div>
      <div
        className={`${position} left-[6.5%] bottom-[0%] w-[87%] h-[42%] rounded-[50%] z-1 opacity-75`}
        // className={`${position} left-[6.5%] bottom-[7%] w-[87%] h-[42%] rounded-[50%] z-1 opacity-75`}
        style={{
          background: "linear-gradient(180deg, rgba(179, 136, 204, 0.52) 0%, #BA93D2 100%)",
          filter: "blur(110px)",
        }}
      ></div>
    </>
  );
}
