import { Note } from "../../store/token/api/types";
import { PageParams } from "../types";

export type CreateNote = {
  tokenId: number | string;
  message: string;
};

export type UpdateNote = {
  tokenId: number | string;
  noteId: number | string;
  like: NoteAction;
};

export enum NoteAction {
  LIKE = "Like",
  DISLIKE = "disLike",
}

export type CommunityNotesData = { items: Note[]; total: number };

export type NotesParams = PageParams & { id: number };
