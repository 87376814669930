import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchTokens } from "../../../api/tokens/getSearchTokens";
import { SearchTokensParams } from "../../../api/tokens/types";

export const fetchSearchTokensListAction = createAsyncThunk(
  "tokens/fetchSearchTokensList",
  async (params: SearchTokensParams) => {
    return getSearchTokens(params);
  },
);
