import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks.ts";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer.ts";
import { useRouterLock } from "../../hooks/useRouterLock.ts";
import Button from "../../components/ui/Button.tsx";

import { steps } from "./steps.tsx";
import { updateIsPumpadutDemoCompletedAction } from "../../store/account/actions/updateIsPumpadutDemoCompleted.ts";
import { setPumpDemoCompleted } from "../../store/account/account.tsx";
import { getCachedImage } from "../../helpers/preloadOnboardingImages.ts";

import welcomeToPumpadur from "../../assets/img/welcomeToPumpadur.png";
import createYourMemeToken from "../../assets/img/createYourMemeToken.png";
import safetyFirst from "../../assets/img/safetyFirst.png";
import tradeInstantly from "../../assets/img/tradeInstantly.png";
import joinTheMeme from "../../assets/img/joinTheMeme.png";

export default function WelcomeToPumpadurPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const imageUrls = [
    welcomeToPumpadur,
    createYourMemeToken,
    safetyFirst,
    tradeInstantly,
    joinTheMeme,
  ];

  useRouterLock();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
  }, [dispatch]);

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      dispatch(updateIsPumpadutDemoCompletedAction());
      dispatch(setPumpDemoCompleted());
      navigate("/pumpadur/main");
    }
  };

  const { title, text, button } = steps[currentStep];

  // Отримуємо зображення з кешу
  const currentImage = getCachedImage(imageUrls[currentStep]);

  return (
    <div className="flex flex-1 flex-col items-center pb-[17px]">
      <div
        className="flex items-center justify-center mb-[30px]"
        style={{
          marginTop: "16vh",
          width: "266px",
          height: "245px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {currentImage ? (
          <img
            className="max-w-full max-h-full object-contain transition-opacity duration-300"
            src={currentImage.src}
            alt="stepImage"
          />
        ) : (
          <div>Loading...</div>
        )}
      </div>
      <div
        className="flex flex-col items-center"
        style={{
          height: "196px",
        }}
      >
        <h1 className="text-[28px] font-bold leading-10 text-center">{title}</h1>
        <p
          className="mt-[20px] text-[18px] text-center w-full max-w-[266px] leading-6 font-medium"
          style={{
            fontFamily: "var(--font-family)",
            color: "var(--hamstercard.pro-white-mine-shaft)",
          }}
        >
          {text}
        </p>
      </div>
      <div className="fixed z-[2000] bottom-0 right-0 left-0 shrink-0 px-4 pb-4">
        <Button
          text={button}
          style="linear-gradient(162deg, #fe3f8f 0%, #e42575 100%)"
          className="text-[14px] py-[1.14em] z-[2000] disabled:!bg-lightGrey"
          handleClick={handleNextStep}
        />
      </div>
    </div>
  );
}
