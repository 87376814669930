import { useEffect } from "react";
import UserPhoto from "../../components/Common/UserPhoto";
import MenuList from "../../components/Pumpadur/Settings/MenuList";
import UserWalletInfo from "../../components/Pumpadur/UserWalletInfo";
import DarkBackground from "../../components/ui/DarkBackground";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import { useSelector } from "react-redux";
import { userIdSelector, userNameSelector } from "../../store/account/account";
import ReferralSystem from "../../components/Common/ReferralSystem";
import { cryptoProfilePublicKeySelector, isActiveWalletSelector } from "../../store/cryptoProfile/cryptoProfileReducer";
import CreatedTokensList from "../../components/Pumpadur/Token/TokenList/MyCreatedTokensList";
import { getUserName } from "../../helpers/getUserName";

const SettingsPage = () => {
  const userId = useSelector(userIdSelector);
  const phantomWalletPublicKey = useSelector(cryptoProfilePublicKeySelector);
  const isActiveWallet = useSelector(isActiveWalletSelector);
  const userName = useSelector(userNameSelector);

  const startPram = `pumpadur/user-profile/${userId}`;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  return (
    <div className="relative pb-[20px]">
      <DarkBackground>
        <div className="flex relative w-full flex-col gap-6 items-center justify-center py-4">
          <UserPhoto styles="w-[88px] h-[88px] rounded-2xl" />
          <p className="text-[14px] text-[#b5b5b5]">Set New Photo (Soon) </p>
          <UserWalletInfo
            phantomWalletPublicKey={phantomWalletPublicKey!}
            isActiveWallet={isActiveWallet}
            userName={getUserName(userName, userId)}
          />
        </div>
      </DarkBackground>
      <MenuList />

      <div>
        <h2 className="text-center text-[12px] font-bold opacity-50 leading-none mt-5 mb-[14px]">Your Referral link</h2>
        <ReferralSystem text="Share" startParam={startPram} />
      </div>
      <CreatedTokensList />
    </div>
  );
};

export default SettingsPage;
