import { Connection, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";

export const createToken = async (
  connection: Connection,
  fromPubkey: PublicKey,
  toPubkey: PublicKey,
  amount: number,
) => {
  const lamports = Math.round(amount * 1_000_000_000);
  console.log("solanaAmount", amount || 0);
  console.log("lamports", lamports);
  console.log("type of lamports", typeof lamports);

  const transaction = new Transaction().add(
    SystemProgram.transfer({
      fromPubkey: fromPubkey,
      toPubkey: toPubkey,
      lamports: lamports,
    }),
  );
  const { blockhash } = await connection.getLatestBlockhash();
  transaction.recentBlockhash = blockhash;
  transaction.feePayer = fromPubkey;
  console.log("transaction", transaction);

  return transaction;
};
