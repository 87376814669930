import { io, Socket } from "socket.io-client";
import { getAccessToken } from "./getAccessToken";

export async function connectToSocket() {
  const token = await getAccessToken();
//   const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
  if (!token) {
    console.error("Unable to connect to WebSocket: No token available");
    return null;
  }

  const socket: Socket = io('wss://api-dev.orbitonx.com', {
    auth: {
      authorization: `Bearer ${token}`,
    },
    transports: ["websocket", "polling"], // Укажите необходимые транспорты
  });

  socket.on("connect", () => {
    console.log("Socket connected:", socket.id);
  });

  socket.on("connect_error", (err) => {
    console.error("Socket connection error:", err);
  });

  socket.on("update", (data) => {
    console.log("Update received:", data);
  });

  socket.on("disconnect", () => {
    console.log("Socket disconnected");
  });

  return socket;
}
