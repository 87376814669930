import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserPhoto from "../../Common/UserPhoto";
import DarkBackground from "../../ui/DarkBackground";
import UserWalletInfo from "../UserWalletInfo";
import { GradientLine } from "../../ui/GradientLine";
import FollowButton from "./FollowButton";
import { userIdSelector } from "../../../store/account/account";
import {
  cryptoDataByUserSelector,
  isActiveWalletSelector,
  myCryptoDataSelector,
} from "../../../store/cryptoProfile/cryptoProfileReducer";
import { getUserName } from "../../../helpers/getUserName";

export enum WalletViewMode {
  MY_WALLET = "my_wallet",
  USER_WALLET = "user_wallet",
}

interface UserSummaryProps {
  walletViewMode: WalletViewMode;
  abilitytoRedirectToWalletManagement?: boolean;
}

const UserSummary = ({ walletViewMode, abilitytoRedirectToWalletManagement = false }: UserSummaryProps) => {
  const myId = useSelector(userIdSelector);
  const userCryptoData = useSelector(cryptoDataByUserSelector);
  const isActiveMyWallet = useSelector(isActiveWalletSelector);
  const myCryptoData = useSelector(myCryptoDataSelector);
  const { userId } = useParams();
  const isMyProfile = walletViewMode === WalletViewMode.MY_WALLET;

  const getUserData = (mode: WalletViewMode) => {
    switch (mode) {
      case WalletViewMode.MY_WALLET:
        return {
          userPhoto: myCryptoData?.updatedCriptoProfile.updatedCriptoProfile.user.photo_url,
          userName: myCryptoData?.updatedCriptoProfile.updatedCriptoProfile.user.username || `user${myId}`,
          followers: myCryptoData?.followers?.followersCount || 0,
          following: myCryptoData?.followers?.followingCount || 0,
          phantomWalletPublicKey: myCryptoData?.updatedCriptoProfile.updatedCriptoProfile.publicKey,
          isActiveWallet: isActiveMyWallet,
          checkFollow: null,
        };
      case WalletViewMode.USER_WALLET:
        return {
          userPhoto: userCryptoData?.user.photo_url,
          userName: userCryptoData?.user.username || `user${userId}`,
          followers: userCryptoData?.followersCount,
          following: userCryptoData?.followingCount,
          phantomWalletPublicKey: userCryptoData?.publicKey,
          isActiveWallet: !!userCryptoData?.publicKey,
          checkFollow: userCryptoData?.checkFollow,
        };
      default:
        return {};
    }
  };

  const { userPhoto, userName, followers, following, checkFollow, phantomWalletPublicKey, isActiveWallet } =
    getUserData(walletViewMode);

  return (
    <DarkBackground>
      <div className="flex relative w-full flex-col gap-6 items-center justify-center py-4">
        <div className="flex flex-col justify-center items-center gap-[14px]">
          <UserPhoto styles="w-[88px] h-[88px] rounded-2xl pb-1" userPhoto={userPhoto} />
          <p className="text-[20px] font-semibold uppercase">{userName}</p>
          <div className="flex text-[#b5b5b5] relative justify-center items-center gap-6">
            <p>{followers} Followers</p>
            <GradientLine className=" h-[20px] w-[1px]" />
            <p>{following} Following</p>
          </div>
        </div>
        {!isMyProfile && userId && (
          <div className="w-full">
            <FollowButton followerId={+myId!} followingId={+userId} checkFollow={checkFollow!} />
          </div>
        )}
        <UserWalletInfo
          phantomWalletPublicKey={phantomWalletPublicKey!}
          userName={getUserName(userName, isMyProfile ? myId! : userId!)}
          isActiveWallet={isActiveWallet!}
          abilitytoRedirectToWalletManagement={abilitytoRedirectToWalletManagement}
        />
      </div>
    </DarkBackground>
  );
};

export default UserSummary;
