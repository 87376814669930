import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllNewTokens } from "../../../api/tokens/getAllNewTokens";
import { PageParams } from "../../../api/tokens/types";

export const fetchAllNewTokensListAction = createAsyncThunk(
  "tokens/fetchAllNewTokensList",
  async (params: PageParams) => {
    return getAllNewTokens(params);
  },
);
