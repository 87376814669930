import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ApiResponse } from "../types.ts";
import { ResponseTransactionssListData, TransactionsByTokenParams } from "./types.ts";

export async function getTransactionsByToken(params: TransactionsByTokenParams) {
  return (
    await axiosInstance.get<ApiResponse<ResponseTransactionssListData>>(apiRoutes.transactions.transactionByToken, {
      params,
    })
  ).data.data;
}
