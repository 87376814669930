import { useSelector } from "react-redux";
import Title from "../../../ui/Title";
import NftItem from "./NftItem";

import nftIcon from "../../../../assets/icons/nft.svg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { clamp } from "lodash-es";
import { useTelegramApp } from "../../../../hooks/useTelegramApp";
import { nftSelector } from "../../../../store/cryptoProfile/cryptoProfileReducer";
import { fetchRewardForNftAction } from "../../../../store/cryptoProfile/actions/fetchRewardForNftAction";
import { useAppDispatch } from "../../../../store/hooks";
import { useToastNotifications } from "../../../../hooks/useToastNotifications";
import { ToastNotificationType } from "../../../../store/toastNotifications/toastNotificationsReducer";
import { moneyFormat, nFormatter } from "../../../../helpers/moneyFormat";
import { useEffect, useState } from "react";
import { fetchMyNftsInChainAction } from "../../../../store/cryptoProfile/actions/fetchMyNftsInChainAction";
import Loader from "../../../ui/Loader";

const NftList = () => {
  const [isPending, setIsPending] = useState<boolean>(true);

  const myNfts = useSelector(nftSelector);
  const [isReceivedReward, setIsReceivedReward] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMyNftsInChainAction()).finally(() => setIsPending(false));
  }, []);

  useEffect(() => {
    setIsReceivedReward(myNfts.some((nft) => nft.reward));
  }, [myNfts]);

  const { showNotification } = useToastNotifications();

  const handleGetReward = async () => {
    const mintAddressArray = myNfts.map((nft) => nft.mintAddress);

    const result = await dispatch(fetchRewardForNftAction(mintAddressArray));
    if (!fetchRewardForNftAction.fulfilled.match(result)) {
      showNotification({
        title: `Something went wrong!`,
        type: ToastNotificationType.Error,
        timeoutMs: 5000,
      });
    } else {
      showNotification({
        title: `You successfully received ${nFormatter(result.payload.coins)} $RCT and ${moneyFormat(result.payload.ticket)} tickets!`,
        type: ToastNotificationType.Success,
        timeoutMs: 5000,
      });
    }
  };

  return (
    <div className="mb-6">
            <Title text="Your OrbitonX NFTs" style="text-left !text-[16px] leading-[16px]  mt-[16px] mb-4" />
      {isPending ? (
        <Loader />
      ) : (
        <Swiper
          slidesPerView={3.5}
          spaceBetween={8}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
        >
          {myNfts?.map((nft, index) => (
            <SwiperSlide key={index}>
              <NftItem nft={nft} showClaimButton={index === 0 && !isReceivedReward} handleGetReward={handleGetReward} />
            </SwiperSlide>
          ))}
          <SwiperSlide key="magic-eden-block">
            <MagicEdenBlock />
          </SwiperSlide>
        </Swiper>
      )}
    </div>
  );
};

export default NftList;

const MagicEdenBlock = () => {
  const tgApp = useTelegramApp();

  const handleOpenSite = () => {
    tgApp.openLink("https://presale.magiceden.io/pay/66c70691991efe89d4f0fc83");
  };

  return (
    <div
      onClick={handleOpenSite}
      className="rounded-2xl  py-2 leading-none flex flex-col gap-[6px] justify-center items-center font-semibold aspect-square border-solid border-[1px] border-[#6c4e7e] bg-[rgba(255,255,255,0.16)] text-center"
      style={{
        fontSize: clamp(window.innerWidth * 0.027, 6, 12),
      }}
    >
      <p className="font-[var(--font-family)] font-semibold text-[12px] leading-[133%] text-center text-[var(--hamstercard.pro-white-mine-shaft)]">
        Buy NFT
      </p>
      <img src={nftIcon} alt="nftIcon" className="w-[24px] h-[26px]" />
      <p className="font-[var(--font-family)] font-semibold text-[12px] leading-[133%] text-center text-[var(--hamstercard.pro-white-mine-shaft)]">
        on MagicEden
      </p>
    </div>
  );
};
