import { axiosInstance } from "../axios.ts";
import { ApiResponse } from "../types.ts";
import { apiRoutes } from "../routes.ts";
import { SaveTransactionData } from "./types.ts";

export async function saveDataForTransaction(data: SaveTransactionData): Promise<any> {
  const response = await axiosInstance.post<ApiResponse<SaveTransactionData>>(
    apiRoutes.transactions.saveDataForTransaction,
    data,
  );
  return response.data.data;
}
