import { useEffect, useState } from "react";
import LastTransaction from "../../components/Pumpadur/Main/LastTransaction";
import TokenList from "../../components/Pumpadur/Main/TokenList";
import TopTokenBanner from "../../components/Pumpadur/Main/TopTokenBanner";
import UserProfileHeader from "../../components/Pumpadur/UserProfileHeader";
import { Token } from "../../store/token/api/types";
import { getTopCapToken } from "../../api/tokens/getTopCapToken";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import { fetchCryptoProfileAction } from "../../store/cryptoProfile/actions/fetchCryptoProfileAction";

const MainPage = () => {
  const [capTopToken, setCapTopToken] = useState<Token | null>(null);
  const navigate = useNavigate();
  const userRole = useAppSelector((state)=> state.account.roleId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (userRole !== 6) {
      navigate("/pumpadur");
    }
  }, [userRole, navigate]);

  useEffect(() => {
    dispatch(fetchCryptoProfileAction())
    dispatch(changeStatusBottomMenu(true));
    getTopCapToken().then((data) => {
      setCapTopToken(data);
    });
  }, []);

  return (
    <div>
      <UserProfileHeader />
      <div className="pt-4">
        <LastTransaction />
      </div>
      <div className="pt-2">
        <TopTokenBanner token={capTopToken} />
      </div>
      <TokenList />
    </div>
  );
};

export default MainPage;
