import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTopCapTokens } from "../../../api/tokens/getTopCapTokens";
import { PageParams } from "../../../api/tokens/types";

export const fetchTopCapTokensListAction = createAsyncThunk(
  "tokens/fetchTopCapTokensList",
  async (params: PageParams) => {
    return getTopCapTokens(params);
  },
);
