import React, { useRef } from "react";

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value?: string;
  label?: string;
  border?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextareaWidthLabel: React.FC<TextareaProps> = React.memo(
  ({ value, className = "", border = false, handleChange, label, ...attrs }) => {
    const { maxLength } = attrs;

    let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    const inputRef = useRef<HTMLTextAreaElement>(null);
    const container = document.querySelector(".main-container") as HTMLElement;

    const handleFocus = () => {
      if (isIOS) {
        container.style.paddingBottom = `${window.innerHeight / 2}px`;
        if (inputRef.current) {
          inputRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
        }
      }
    };
    const handleBlur = () => {
      if (isIOS) {
        container.style.paddingBottom = "0px";
        window.scroll(0, 0);
      }
    };

    return (
      <div className="rounded-2xl bg-mediumGrey/45">
        <div className="relative px-4 h-[160px]">
          <div
            className={`relative h-full pt-7 pb-[20px] ${border ? "border-solid border-b-[1px] border-[#fff]/10" : ""}`}
          >
            <textarea
              ref={inputRef}
              value={value}
              onFocus={handleFocus}
              onBlur={handleBlur}
              {...attrs}
              onChange={handleChange}
              className={`peer m-0 block h-full w-full rounded bg-transparent bg-clip-padding text-sm scrollbar-hide text-white transition duration-200 ease-linear font-medium leading-6 placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:outline-none peer-focus:text-primary resize-none overflow-auto ${className}`}
              placeholder=""
            />
            <label className="pointer-events-none absolute left-0 top-0 origin-[0_0] py-4 text-base text-white/50 transition-all duration-200 ease-linear transform peer-focus:-translate-y-2 peer-focus:text-[12px] peer-focus:text-white/50 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-[12px] motion-reduce:transition-none">
              {label}
            </label>
          </div>
          <p className="absolute right-4 bottom-2 font-semibold text-[10px] text-[#a199a6] leading-none">
            {value?.length}/{maxLength}
          </p>
        </div>
      </div>
    );
  },
);

export default TextareaWidthLabel;
