import { axiosInstance } from "../axios.ts";
import { CurrentCountTokensAndPrice } from "../../store/token/api/types.ts";
import { ApiResponse } from "../types.ts";
import { apiRoutes } from "../routes.ts";
import { GetCountTokensAndPriceBody } from "./types.ts";

export async function getCountTokensAndPrice(data: GetCountTokensAndPriceBody): Promise<CurrentCountTokensAndPrice> {
  const response = await axiosInstance.post<ApiResponse<CurrentCountTokensAndPrice>>(
    apiRoutes.tokens.countTokensAndPrice,
    data,
  );
  return response.data.data;
}
