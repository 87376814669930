import { useEffect, useState } from "react";
import { ResponseTransactionssListData } from "../../../api/transactions/types";
import InfiniteScroll from "react-infinite-scroll-component";
import TransactionList from "../Profile/Transaction/TransactionList";
import { PageParams } from "../../../api/types";

interface TransactionListWithInfiniteScrollProps<T> {
  pageSize?: number;
  addParams?: T;
  fetchFunction: (params: T & PageParams) => Promise<ResponseTransactionssListData>;
}

const TransactionListWithInfiniteScroll = <T extends object>({
  pageSize = 2,
  addParams,
  fetchFunction,
}: TransactionListWithInfiniteScrollProps<T>) => {
  const [transactions, setTransactions] = useState<ResponseTransactionssListData | null>(null);
  const [pageTransactions, setPageTransactions] = useState(1);

  const hasMore = transactions ? transactions.total > transactions.items.length : false;

  const loadMore = () => {
    setPageTransactions((prevPage) => prevPage + 1);
  };

  const fetchTransactions = async () => {
    try {
      const params = {
        page: pageTransactions,
        pageSize,
        ...addParams,
      } as T & PageParams;

      const result = await fetchFunction(params);
      setTransactions((prevTransactions) => {
        if (!prevTransactions) return result;
        return {
          ...prevTransactions,
          items: [...prevTransactions.items, ...result.items],
          total: result.total,
        };
      });
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [pageTransactions]);

  return (
    <>
      {transactions && (
        <InfiniteScroll
          dataLength={transactions.items.length}
          next={loadMore}
          className="relative flex flex-col gap-2 pt-3"
          hasMore={hasMore}
          loader={<span></span>}
        >
          <TransactionList transactions={transactions.items} />
        </InfiniteScroll>
      )}
    </>
  );
};

export default TransactionListWithInfiniteScroll;
