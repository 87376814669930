import React, { useRef, useState } from "react";
import placeholderIcon from "../../../assets/icons/photo-placeholder.svg";
import EditIcon from "../../../assets/icons/edit.svg?react";
import { GradientCard } from "../../ui/GradientCard";
import { PreloaderCircle } from "../../ui/PreloaderCircle";

import Resizer from "react-image-file-resizer";

interface TokenImageNFTChooseProps {
  selectedTokenImage: null | File | Blob;
  setSelectedTokenImage: (image: File | null | Blob) => void;
}

const TokenImageNFTChoose: React.FC<TokenImageNFTChooseProps> = React.memo(
  ({ selectedTokenImage, setSelectedTokenImage }) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleButtonClick = () => {
      fileInputRef.current?.click();
    };

    const resizeFile = (file: File) =>
      new Promise<Blob>((resolve) => {
        Resizer.imageFileResizer(
          file,
          300,
          300,
          "JPEG",
          100,
          0,
          (blob) => {
            resolve(blob as Blob);
          },
          "blob",
        );
      });

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);
      const file = event.target.files?.[0];
      const image = await resizeFile(file!);
      if (image) setSelectedTokenImage(image);
      setIsLoading(false);
    };

    return (
      <div className="relative flex items-center gap-6 pb-[22px] justify-center ">
        <div className="flex flex-col items-center gap-[14px]">
          <p className={`text-[12px] font-medium text-paleGrey ${selectedTokenImage ? "invisible" : ""}`}>
            Choose token image
          </p>
          <div className="size-[88px] flex justify-center items-center rounded-2xl bg-[#3a2e3d]">
            {isLoading ? (
              <PreloaderCircle />
            ) : selectedTokenImage ? (
              <img
                src={URL.createObjectURL(selectedTokenImage)}
                alt="Selected photo"
                className="rounded-2xl size-full object-cover"
              />
            ) : (
              <img src={placeholderIcon} alt=" placeholder" className="rounded-2xl size-6 object-cover" />
            )}
          </div>
          <div className="pb-[5px]">
            <GradientCard backgroundColor="#242026">
              <button
                type="button"
                onClick={handleButtonClick}
                className={`py-[6px] px-5 flex gap-[6px] rounded-3xl ${selectedTokenImage ? "text-white" : "text-[#aa9db2]"}  leading-4 text-[12px] font-semibold `}
              >
                <EditIcon className={`size-4 ${selectedTokenImage ? "fill-white" : "fill-[#aa9db2]"}`} />
                <span>Edit</span>
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
                accept="image/png, image/jpeg"
              />
            </GradientCard>
          </div>
        </div>
      </div>
    );
  },
);

export default TokenImageNFTChoose;
