import { useSelector } from "react-redux";
import { TradeType } from "./FilterTrade";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";
import Chip from "../../../ui/Chip";

const AmountOption = ({
  amount,
  onClick,
  isActiveAmount,
  activeTradeType,
}: {
  amount: number | string;
  isActiveAmount?: boolean;
  activeTradeType: TradeType;
  onClick: (amount: number | string) => void;
}) => {
  const token = useSelector(currentTokenSelector);
  return (
    <div className="flex-shrink-0" onClick={() => onClick(amount)}>
      <Chip
        text={
          amount === "MAX"
            ? "MAX" // Отображение "MAX" без валюты
            : ` ${amount} ${activeTradeType === TradeType.BUY ? "SOL" : `$${token?.ticker}`} `
        }
        bgColor={`${isActiveAmount ? "bg-white/30" : "bg-white/15"} `}
        textSize={`text-[14px] ${isActiveAmount ? "text-white" : "text-white/50"} uppercase`}
        paddingX="px-[17px]"
        height="h-[32px]"
      />
    </div>
  );
};

export default AmountOption;
