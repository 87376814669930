import ReactDOM from "react-dom";
import { BottomSheet } from "../../ui/BottomSheet";
import Button from "../../ui/Button";
import { useState } from "react";
import { FeeData } from "../../../api/tokens/types";
import { useTransaction } from "../../../hooks/useTransaction";
import { getDataSavedToken } from "../../../api/tokens/getDataSavedToken";
import { useToastNotifications } from "../../../hooks/useToastNotifications";
import { ToastNotificationType } from "../../../store/toastNotifications/toastNotificationsReducer";

interface ConfirmCreateTokenProps {
  fee: FeeData | null;
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
  handleConfirm: () => Promise<boolean>;
}

export function ConfirmCreateToken({ isOpened, setIsOpened, handleConfirm, fee }: ConfirmCreateTokenProps) {
  const [isPending, setIsPending] = useState<boolean>(false);

  const { createTokenTransaction } = useTransaction();
  const { showNotification } = useToastNotifications();

  const handleClick = async () => {
    setIsPending(true);
    const dataSavedToken = await getDataSavedToken();
    if (dataSavedToken) {
      showNotification({
        title: "Error! The previous transaction is not completed!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      setIsPending(false);
      setIsOpened(false);
    } else {
      const result = await handleConfirm();

      if (result)
        setTimeout(async () => {
          console.log("Transaction signed and sent");
          try {
            await createTokenTransaction(fee?.totalCost || 0);
            console.log("Transaction signed and sent");
          } catch (err) {
            console.error("Error signing and sending transaction:", err);
          }
          setIsPending(false);
        }, 2000);
      else {
        setIsPending(false);
      }
    }
  };

  return ReactDOM.createPortal(
    <div className="flex flex-col size-full relative z-[2000] justify-center leading-none ">
      <BottomSheet
        isOpened={isOpened}
        onClose={() => setIsOpened(false)}
        title="Confirm Create Your Token"
        classNameForChildrenContainer="pt-3 pb-2 px-2"
      >
        <div className="rounded-2xl bg-mediumGrey/25 px-2 pt-[10px] pb-4 mb-[14px]">
          <div className="flex justify-between px-2 border-solid border-b-[1px] border-white/10 pb-3">
            <p className="font-semibold text-lavenderGray">Transaction Fee</p>
            <div className="font-bold flex gap-2 items-center">
              <p>{fee?.fee} SOL</p>
            </div>
          </div>

          <div className="flex justify-between pt-3 px-2 border-solid border-b-[1px] border-white/10 pb-3">
            <p className="font-semibold text-lavenderGray">Rent Exemption</p>
            <div className="font-bold">
              <p>{fee?.rentExemption} SOL</p>
            </div>
          </div>
          <div className="flex justify-between pt-3 px-2">
            <p className="font-semibold text-lavenderGray">Fee For Metadata</p>
            <div className="font-bold">
              <p>{fee?.metadataAccountFee} SOL</p>
            </div>
          </div>
        </div>
        <p className="text-center pb-2 font-medium">
          <span className="pr-2 text-lavenderGray">Total Cost:</span>
          <span>{fee?.totalCost} SOL</span>
        </p>
        <div className="text-center pt-[1vh] leading-none ">
          <Button
            text={"Confirm"}
            isPending={isPending}
            handleClick={handleClick}
            style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
          />
        </div>{" "}
      </BottomSheet>
    </div>,
    document.getElementById("root")!,
  );
}
