import { useEffect, useState } from "react";

export const useVisualViewport = () => {
  const getViewportSize = () => ({
    width: window.visualViewport?.width || window.innerWidth,
    height: window.visualViewport?.height || window.innerHeight,
  });

  const [viewportSize, setViewportSize] = useState(getViewportSize);

  useEffect(() => {
    const handleResize = () => {
      // Отримання нового розміру вікна перегляду
      const newSize = getViewportSize();

      // Додаткова логіка для iOS, щоб запобігти неправильним вимірам
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIOS && window.visualViewport) {
        const newHeight = Math.min(window.visualViewport.height, window.innerHeight);
        newSize.height = newHeight;
      }

      setViewportSize(newSize);
    };

    // Додаємо слухачів подій
    window.visualViewport?.addEventListener("resize", handleResize);
    window.visualViewport?.addEventListener("scroll", handleResize);

    // Спрацьовуємо при першому рендері
    handleResize();

    // Очищаємо слухачів подій при розмонтаженні компонента
    return () => {
      window.visualViewport?.removeEventListener("resize", handleResize);
      window.visualViewport?.removeEventListener("scroll", handleResize);
    };
  }, []);

  return viewportSize;
};
