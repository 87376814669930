// import { useSelector } from "react-redux";
// import { nftsConditionsSelector } from "../../../../store/cryptoProfile/cryptoProfileReducer";
// import { moneyFormat } from "../../../../helpers/moneyFormat";

// import ticketIcon from "../../../../assets/icons/ticket.svg";
// import CurrencyDisplay from "../../../Common/CurrencyDisplay";

const NftsConditions = () => {
  // const nftsConditions = useSelector(nftsConditionsSelector);
  // const { discountForMainNFTSale, discountOnNFTPresale, forInGameBalance, forUnlockPassiveInGameIncome } =
  //   nftsConditions!;
  return (
    <div className="px-[6vw]">
      <div className="mb-[18px]">
        <p className="font-[var(--font-family)] font-bold text-[20px] leading-[20px] text-center text-[var(--hamstercard.pro-white-mine-shaft]">
          OrbitonX Rockets NFT
        </p>
        <p className="font-[var(--font-family)] font-bold text-[20px] leading-[20px] text-center text-[#FFCE01] uppercase">
          LIMITED PRESALE!
        </p>
      </div>
      <ul className="grid grid-cols-2 gap-[10.5px]">
        <li className="h-[60px] flex items-center justify-center gap-x-2 p-1  border-white/5 border-solid border-0 border-t first-of-type:border-t-0 bg-white/[8%] rounded-xl">
          <div className="text-[16px] flex flex-col justify-center gap-1">
            <div className="flex gap-[2px] justify-center items-center">
              <div className="leading-none font-bold inline-block">
                <strong className="font-[var(--font-family)] font-bold text-[16px] text-center text-[#fec801] leading-none">
                  up to 20%
                </strong>
              </div>
            </div>
            <p className="font-[var(--font-family)] font-medium text-[14px] text-center text-[var(--hamstercard.pro-white-mine-shaft)] leading-4">
              rakeback for NFT
              <br />
              owners
            </p>
          </div>
        </li>
        <li className="h-[60px] flex items-center justify-center gap-x-2 p-1  border-white/5 border-solid border-0 border-t first-of-type:border-t-0 bg-white/[8%] rounded-xl">
          <div className="text-[16px] flex flex-col justify-center gap-1">
            <div className="flex gap-[2px] justify-center items-center">
              <div className="leading-none font-bold inline-block">
                <strong className="font-[var(--font-family)] font-bold text-[16px] text-center text-[#fec801] leading-none">
                  The NFT owner
                </strong>
              </div>
            </div>
            <p className="font-[var(--font-family)] font-medium text-[14px] text-center text-[var(--hamstercard.pro-white-mine-shaft)] leading-4">
              receives a % of the
              <br />
              service fee
            </p>
          </div>
        </li>
        <li className="h-[60px] flex items-center justify-center gap-x-2 p-1  border-white/5 border-solid border-0 border-t first-of-type:border-t-0 bg-white/[8%] rounded-xl">
          <div className="text-[16px] flex flex-col justify-center gap-1">
            <div className="flex gap-[2px] justify-center items-center">
              {/* <div className="leading-none font-bold inline-block">
                <strong className="font-[var(--font-family)] font-bold text-[16px] text-center text-[#fec801] leading-none">{moneyFormat(100000)}</strong>
              </div> */}
              {/* <CurrencyDisplay textStyle="text-[16px] text-yellow" value={100000} /> */}
              <strong className="font-[var(--font-family)] font-bold text-[16px] text-center text-[#fec801] leading-none">
                4
              </strong>
            </div>
            <p className="font-[var(--font-family)] font-medium text-[14px] text-center text-[var(--hamstercard.pro-white-mine-shaft)] leading-4">
              Rarity Levels
            </p>
          </div>
        </li>
        <li className="h-[60px] flex items-center justify-center gap-x-2 p-1  border-white/5 border-solid border-0 border-t first-of-type:border-t-0 bg-white/[8%] rounded-xl">
          <div className="text-[16px] flex flex-col justify-center gap-1">
            <div className="flex gap-[2px] justify-center items-center">
              <div className="leading-none font-bold flex gap-[2px]">
                <strong className="font-[var(--font-family)] font-bold text-[16px] text-center text-[#fec801] leading-none">
                  Each NFT
                </strong>
              </div>
            </div>
            <p className="font-[var(--font-family)] font-medium text-[12px] text-center text-[var(--hamstercard.pro-white-mine-shaft)] leading-[108%]">
              provide a rakeback
              <br /> and passive income
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default NftsConditions;
