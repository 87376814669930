import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import UserSummary, { WalletViewMode } from "../../components/Pumpadur/Profile/UserSummary";
import CreatedTokensListByUser from "../../components/Pumpadur/Token/TokenList/CreatedTokensListByUser";
import { useSelector } from "react-redux";
import { fetchCryptoProfileByUserAction } from "../../store/cryptoProfile/actions/fetchCryptoProfileByUserAction";
import { useParams } from "react-router-dom";
import { cryptoDataByUserSelector } from "../../store/cryptoProfile/cryptoProfileReducer";
import { getTransactionsByUserId } from "../../api/transactions/getTransactionsByUserId";
import ReferralSystem from "../../components/Common/ReferralSystem";
import { BackButton } from "@twa-dev/sdk/react";
import TransactionListWithInfiniteScroll from "../../components/Pumpadur/Transaction/TransactionListWithInfiniteScroll";
import Title from "../../components/ui/Title";

const UserProfilePage = () => {
  const dispatch = useAppDispatch();
  const userCryptoData = useSelector(cryptoDataByUserSelector);

  const { userId } = useParams();
  const startPram = `pumpadur/user-profile/${userId}`;

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    dispatch(fetchCryptoProfileByUserAction(+userId!));

    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, [dispatch, userId]);

  if (!userCryptoData || !userCryptoData.user) {
    return null;
  }

  return (
    <div className="relative pb-[20px]">
      <BackButton />
      <UserSummary walletViewMode={WalletViewMode.USER_WALLET} />
      <div>
        <h2 className="text-center text-[12px] font-bold opacity-50 leading-none mt-5 mb-[14px]"> Referral link</h2>
        <ReferralSystem text="Share" startParam={startPram} />
      </div>
      <CreatedTokensListByUser />
      <div className="pt-8">
        <Title text="Last Transactions" />
        <TransactionListWithInfiniteScroll fetchFunction={getTransactionsByUserId} addParams={{ userId: +userId! }} />
      </div>
    </div>
  );
};

export default UserProfilePage;
