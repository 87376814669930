import { useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import Title from "../../components/ui/Title";
import NotesList from "../../components/Pumpadur/Notes/NotesList";
import NoteForm from "../../components/Pumpadur/Notes/NoteForm";
import { useParams } from "react-router-dom";
import { fetchNotesForTokenAction } from "../../store/token/actions/fetchNotesForTokenAction";
import { useSelector } from "react-redux";
import { clearCommunityNotes, communityNotesSelector } from "../../store/token/tokensReducer";
import ReactDOM from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { NotesParams } from "../../api/communityNotes/types";

const PAGE_SIZE = 10;

const CommunityNotesPage = () => {
  const { tokenId } = useParams();

  const communityNotes = useSelector(communityNotesSelector);

  const [page, setPage] = useState(1);
  const [inputNote, setInputNote] = useState<string>("");

  const dispatch = useAppDispatch();

  const hasMore = communityNotes ? communityNotes?.total > communityNotes?.items.length : false;

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    return () => {
      dispatch(changeStatusBottomMenu(true));
      dispatch(clearCommunityNotes());
    };
  }, []);

  useEffect(() => {
    const params: NotesParams = {
      id: +tokenId!,
      page: page,
      pageSize: PAGE_SIZE,
    };
    if (tokenId) dispatch(fetchNotesForTokenAction(params));
  }, [page]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pb-[100px]">
      <Title text="Community notes" />
      {communityNotes && (
        <InfiniteScroll
          dataLength={communityNotes.items.length}
          next={loadMore}
          className="relative flex flex-col gap-2 pt-3"
          hasMore={hasMore}
          loader={<span></span>}
          scrollThreshold={1}
        >
          <NotesList communityNotes={communityNotes?.items} />{" "}
        </InfiniteScroll>
      )}

      {ReactDOM.createPortal(
        <NoteForm tokenId={tokenId!} value={inputNote} handleChange={setInputNote} />,
        document.body,
      )}
    </div>
  );
};

export default CommunityNotesPage;
