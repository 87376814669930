import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ResponseTokensList, TokensByUserParams } from "./types.ts";

export async function getTokensByUser(params: TokensByUserParams) {
  return (
    await axiosInstance.get<ResponseTokensList>(apiRoutes.tokens.tokensByUser, {
      params,
    })
  ).data.data;
}
