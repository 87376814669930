import { axiosInstance } from "../axios.ts";
import { CurrentCountTokensForSell } from "../../store/token/api/types.ts";
import { ApiResponse } from "../types.ts";
import { apiRoutes } from "../routes.ts";
import { GetCountTokensAndPriceBody } from "./types.ts";

export async function getCountTokensForSell(data: GetCountTokensAndPriceBody): Promise<CurrentCountTokensForSell> {
  const response = await axiosInstance.post<ApiResponse<CurrentCountTokensForSell>>(
    apiRoutes.tokens.countTokensForSell,
    data,
  );
  return response.data.data;
}
