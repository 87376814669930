export function moneyFormat(number: number, maximumFractionDigits: number = 2) {
  return number.toLocaleString("en-US", { maximumFractionDigits: maximumFractionDigits });
}
export function moneyFormatWithTwoDigits(number: number, fractionDigits: number = 2) {
  return number.toLocaleString("en-US", {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
}

export function nFormatter(number: number, maximumFractionDigits: number = 2) {
  return number.toLocaleString("en-US", {
    maximumFractionDigits: maximumFractionDigits,
    notation: "compact",
    compactDisplay: "short",
  });
}
