import { useEffect, useState } from "react";
import { useTelegramApp } from "../../hooks/useTelegramApp";
import Button from "../ui/Button";
import { CopyToClipboardInput } from "../ui/CopyToClipboardInput";

const ReferralSystem = ({
  text = "Share",
  background = "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)",
  startParam,
}: {
  startParam: string;
  text?: string;
  background?: string;
}) => {
  const [inviteUrl, setInviteUrl] = useState<string>("");
  const telegramApp = useTelegramApp();
  let url = `${import.meta.env.VITE_TG_URL}/${import.meta.env.VITE_MINI_APP_NAME}`;

  useEffect(() => {
    const param = btoa(startParam);
    const inviteLink = `${url}?startapp=path=${param}`;
    setInviteUrl(inviteLink);
  }, []);

  const handleInviteFriend = () => {
    const fullUrl = `https://t.me/share/url?url=${encodeURIComponent(inviteUrl)}`;
    telegramApp.openTelegramLink(fullUrl);
  };

  return (
    <div>
      <CopyToClipboardInput value={inviteUrl || ""} />
      <div className="mt-4">
        <Button
          text={text}
          style={background}
          className="text-[14px] py-[1.14em] disabled:!bg-lightGrey"
          handleClick={handleInviteFriend}
        />
      </div>
    </div>
  );
};

export default ReferralSystem;
