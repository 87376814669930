import { refreshToken } from "../api/auth/refresh-token";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../constants/auth";

export async function getAccessToken(): Promise<string | null> {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  
    if (!accessToken) {
      try {
        const refreshTokenValue = localStorage.getItem(REFRESH_TOKEN_KEY);
        if (!refreshTokenValue) throw new Error("No refresh token available");
  
        const refreshTokenResponse = await refreshToken();
        localStorage.setItem(ACCESS_TOKEN_KEY, refreshTokenResponse.token);
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshTokenResponse.refreshToken);
        return refreshTokenResponse.token;
      } catch (error) {
        console.error("Failed to refresh token:", error);
        return null;
      }
    }
  
    return accessToken;
  }