
type Step = {
  title: React.ReactNode;
  text: string;
  button: string;
};

export const steps: Step[] = [
  {
    title: (
      <>
        🚀 Welcome to <br /> Pumpadur!
      </>
    ),
    text: "Your ultimate launchpad for creating and trading MEME tokens with ease and safety. Let’s pump it up!",
    button: "NEXT",
  },
  {
    title: (
      <>
        🛠️ Create Your <br /> MEME Token
      </>
    ),
    text: "Launch your token in just a few clicks! No coding, no hassle - just creativity and fun.",
    button: "NEXT",
  },
  {
    title: (
      <>
        🔒 Safety First!
      </>
    ),
    text: "Our smart contracts ensure your funds are secure and prevent rug pulls. Trade with confidence!",
    button: "NEXT",
  },
  {
    title: (
      <>
        📈 Trade Instantly
      </>
    ),
    text: "Kickstart trading as soon as your token is live. Watch your price rise with every new buyer!",
    button: "NEXT",
  },
  {
    title: (
      <>
        🎢 Join the Meme <br /> Movement
      </>
    ),
    text: "Be part of the wildest token community. Trade, have fun, and make MEME history!",
    button: "Let’s Pump It!",
  },
];
