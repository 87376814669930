import { createAsyncThunk } from "@reduxjs/toolkit";

import { GetCountTokensAndPriceBody } from "../../../api/tokens/types";
import { getCountTokensAndPrice } from "../../../api/tokens/getCountTokensAndPrice";

export const fetchCountTokensAndPriceAction = createAsyncThunk(
  "tokens/fetchCountTokensAndPrice",
  async (data: GetCountTokensAndPriceBody) => {
    return getCountTokensAndPrice(data);
  },
);
