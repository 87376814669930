export interface SettingMenuItem {
  id: string;
  title: string;
  icon: string;
  link: string;
}

export const mainMenuItems: SettingMenuItem[] = [
  {
    id: "create-token",
    title: "My token transactions",
    icon: "add-icon",
    link: "/pumpadur/my-token-transactions",
  },
  // {
  //   id: "create-token",
  //   title: "How to create and promote token",
  //   icon: "add-icon",
  //   // link: "/create-promote-token",
  //   link: "",
  // },
  // {
  //   id: "gas-referral",
  //   title: "GAS XP Points & Referral",
  //   icon: "present-icon",
  //   // link: "/gas-referral",
  //   link: "",
  // },
  // {
  //   id: "faq",
  //   title: "FAQ",
  //   icon: "faq-icon",
  //   // link: "/faq",
  //   link: "",
  // },
  // {
  //   id: "support",
  //   title: "Support",
  //   icon: "support-icon",
  //   // link: "/support",
  //   link: "",
  // },
];

export const profileMenuItem: SettingMenuItem[] = [
  {
    id: "profile",
    title: "My Profile",
    icon: "profile-icon",
    link: "/pumpadur/profile",
  },
];
export const profileMenuItem2: SettingMenuItem[] = [
  {
    id: "profile1",
    title: "My Profile",
    icon: "profile-icon",
    // link: "/profile",
    link: "",
  },
];
