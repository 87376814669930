import { useSelector } from "react-redux";
import TokenImage from "../TokenImage";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";
import CreatedBy from "../TokenDetails/CreatedBy";

const TokenHeader = () => {
  const token = useSelector(currentTokenSelector);
  const { name, ticker, userName, logo } = token!;

  const slicedName = name.length > 15 ? `${name.slice(0, 12)}...` : name;
  return (
    <div className="relative w-full mt-0 ">
      <div className="relative -top-4 w-[100vw] -left-4 -right-4 h-[50vh]">
        <TokenImage imageSrc={logo} classesName="size-full object-cover bg-[#b190b6]" />
      </div>
      <div className="absolute  w-[100vw]  z-10 top-[34vh] -left-4  min-h-[16vh]">
        <div className="relative">
          <div
            className="absolute min-h-[16vh] w-full backdrop-blur-md blur-[32px]"
            style={{
              background: "linear-gradient(0deg, #301d3c 0%, rgba(48, 29, 60, 0) 100%)",
            }}
          ></div>
          <div className="relative z-[40] flex justify-between px-4 pt-[3vh]">
            <div>
              <p className="font-semibold text-[18px] pb-[2vh]">{slicedName}</p>
              <CreatedBy userName={userName} />
            </div>
            <p className="font-semibold text-[18px] text-paleGrey">${ticker}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenHeader;
