import { createAsyncThunk } from "@reduxjs/toolkit";

import { GetCountTokensAndPriceBody } from "../../../api/tokens/types";
import { getCountTokensForSell } from "../../../api/tokens/getCountTokensForSell";

export const fetchCountTokensForSellAction = createAsyncThunk(
  "tokens/fetchCountTokensForSell",
  async (data: GetCountTokensAndPriceBody) => {
    return getCountTokensForSell(data);
  },
);
