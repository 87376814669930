import { useEffect } from "react";
import { useTgChatId } from "./useTgChatId.ts";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../constants/auth.ts";
import { login } from "../api/auth/login.ts";
import { useTelegramApp } from "./useTelegramApp.ts";
import { DataLayerEventNames, useDataLayer } from "./useDataLayer.ts";
import { decryptUserData } from "../helpers/decryptUserData.ts";

export enum AuthErrorReason {
  NO_CHAT_ID = "NO_CHAT_ID",
  UNKNOWN = "UNKNOWN",
}

interface UseAuthInput {
  onLoginSuccess?: () => void;
  onLoginError?: (reason: AuthErrorReason) => void;
}

export function useAuth({ onLoginSuccess, onLoginError }: UseAuthInput) {
  const { chatId } = useTgChatId();
  const { initDataUnsafe } = useTelegramApp();
  const { trackEvent } = useDataLayer();

  useEffect(() => {
    // const hasAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY)
    //
    // if (hasAccessToken) {
    //     return onLoginSuccess?.()
    // }
    if (!chatId) {
      onLoginError?.(AuthErrorReason.NO_CHAT_ID);
      return;
    }

    loginWrapper(chatId);

    async function loginWrapper(chatId: string) {
      try {
        const { start_param } = initDataUnsafe || {};
        console.log(start_param)
        
        const response = await login(chatId, initDataUnsafe, start_param);
        const { data, status } = response;
        setTimeout(() => {
          console.log(start_param)
          console.log({ data, status })
        }, 5000);
        if (status === 201) {
          trackEvent(DataLayerEventNames.launchFirstTime);
        }

        const { token, refreshToken } = data;
        const { token: decToken, refreshToken: decFefreshToken } = decryptUserData(token, refreshToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, decFefreshToken);
        localStorage.setItem(ACCESS_TOKEN_KEY, decToken);

        onLoginSuccess?.();
      } catch (err) {
        onLoginError?.(AuthErrorReason.UNKNOWN);
      }
    }
  }, [chatId]);
}
