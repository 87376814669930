import { useSelector } from "react-redux";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";

import chanelIcon from "../../../../assets/icons/chanel.svg";
import chatIcon from "../../../../assets/icons/chat.svg";
import xIcon from "../../../../assets/icons/x.svg";
import { useTelegramApp } from "../../../../hooks/useTelegramApp";

interface MediaLink {
  link: string;
  title: string;
  icon: string;
}

const SocialMediaLinks = () => {
  const token = useSelector(currentTokenSelector);
  const { tgChannelLink, tgChatLink, xTwitter } = token!;
  const links: MediaLink[] = [
    {
      link: tgChannelLink,
      title: "Chanel",
      icon: chanelIcon,
    },
    {
      link: tgChatLink,
      title: "Chat",
      icon: chatIcon,
    },
    {
      link: xTwitter,
      title: "X",
      icon: xIcon,
    },
  ];
  return (
    <div className="relative z-[30] flex gap-1 pt-1">
      {links.map((item, index) => (
        <SocialMediaLink item={item} key={index} />
      ))}
    </div>
  );
};

const SocialMediaLink = ({ item }: { item: MediaLink }) => {
  const { icon, link, title } = item;

  const tgApp = useTelegramApp();

  const handleClick = () => {
    tgApp.openLink(link || "");
  };
  return (
    <div
      className="w-1/3 text-center py-3 rounded-2xl bg-white/15 flex flex-col justify-center items-center gap-[6px] leading-none cursor-pointer"
      onClick={handleClick}
    >
      <img src={icon} alt={title} className="size-6" />
      <p className="font-semibold text-[10px] uppercase">{title}</p>
    </div>
  );
};

export default SocialMediaLinks;
