import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ApiResponse, PageParams } from "../types.ts";
import { ResponseTransactionssListData } from "./types.ts";

export async function getMyTransactions(params: PageParams) {
  return (
    await axiosInstance.get<ApiResponse<ResponseTransactionssListData>>(apiRoutes.transactions.myTransaction, {
      params,
    })
  ).data.data;
}
