import CryptoJS from "crypto-js";

const encryptionKey = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_COMBO_ENCRYPTION_KEY);

export function encryptString(data: string): { encryptedData: string } {
  const iv = CryptoJS.lib.WordArray.random(16);

  const encrypted = CryptoJS.AES.encrypt(data, encryptionKey, { iv });

  const encryptedDataStr = `${encrypted.toString()}:${iv.toString(CryptoJS.enc.Hex)}`;

  return { encryptedData: encryptedDataStr };
}

export function decryptUserData(
  encryptedToken: string,
  encryptedRefreshToken: string,
): { token: string; refreshToken: string } {
  const [encryptedTokenStr, ivTokenStr] = encryptedToken.split(":");
  const [encryptedRefreshTokenStr, ivRefreshTokenStr] = encryptedRefreshToken.split(":");

  const ivToken = CryptoJS.enc.Hex.parse(ivTokenStr);
  const ivRefreshToken = CryptoJS.enc.Hex.parse(ivRefreshTokenStr);

  const encryptedTokenBytes = CryptoJS.enc.Base64.parse(encryptedTokenStr);
  const encryptedRefreshTokenBytes = CryptoJS.enc.Base64.parse(encryptedRefreshTokenStr);

  const tokenCipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: encryptedTokenBytes,
  });

  const refreshTokenCipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: encryptedRefreshTokenBytes,
  });

  const decryptedToken = CryptoJS.AES.decrypt(tokenCipherParams, encryptionKey, { iv: ivToken });
  const decryptedRefreshToken = CryptoJS.AES.decrypt(refreshTokenCipherParams, encryptionKey, {
    iv: ivRefreshToken,
  });

  const token = decryptedToken.toString(CryptoJS.enc.Utf8);
  const refreshToken = decryptedRefreshToken.toString(CryptoJS.enc.Utf8);

  return { token, refreshToken };
}
