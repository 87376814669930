import TransactionItem from "./TransactionItem";
import { Transaction } from "../../../../store/token/api/types";

const TransactionList = ({ transactions }: { transactions: Transaction[] }) => {
  return (
    <>
      <div className="flex flex-col gap-2">
        {transactions.map((transaction) => (
          <TransactionItem key={transaction.id} transaction={transaction} />
        ))}
      </div>
    </>
  );
};

export default TransactionList;
