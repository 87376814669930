import { useEffect, useState } from "react";
import user1 from "../../../assets/icons/user.svg";
import { getLastTransaction } from "../../../api/transactions/getLastTransaction";
import { Transaction, TransactionsStatus } from "../../../store/token/api/types";
import { getTimeDifference } from "../../../helpers/getTimeDifference";
import TokenImage from "../Token/TokenImage";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { userIdSelector } from "../../../store/account/account";
import { truncateDecimal } from "../../../helpers/truncateDecimal";

const LastTransaction = () => {
  const [lastTransaction, setLastTransaction] = useState<Transaction | null>(null);
  const myId = useSelector(userIdSelector);

  const fetchLastTransaction = () => {
    getLastTransaction().then((data) => {
      if (lastTransaction?.createdAt !== data.createdAt) {
        setLastTransaction(data);
      }
    });
  };

  useEffect(() => {
    fetchLastTransaction();

    const intervalId = setInterval(fetchLastTransaction, 10000);

    return () => clearInterval(intervalId);
  }, []);

  if (!lastTransaction) {
    return null;
  }
  const { coinQuantity, createdAt, status, user, token, tokenId, tokenAmount } = lastTransaction;
  const { ticker, logo } = token!;
  const { first_name, id: userId } = user!;

  let message = ``;
  switch (status) {
    case TransactionsStatus.BUY: {
      message = `Bought ${truncateDecimal(+coinQuantity, 7)} SOL of`;
      break;
    }
    case TransactionsStatus.SELL: {
      message = `Sold ${truncateDecimal(+tokenAmount, 7)} `;
      break;
    }
  }
  // message += `  ${truncateDecimal(+coinQuantity, 7)} SOL of`;
  const isMyProfile = userId === myId;

  return (
    <div className={"flex gap-1 items-start  rounded-[16px]  text-[14px]  p-1 justify-between pr-3 bg-mediumGrey "}>
      <div className="flex items-center gap-[10px]">
        <img src={user1} className="relative bg-lightPurple rounded-[12px]  w-[40px] h-[40px] bg-contain" />
        <div>
          <NavLink to={isMyProfile ? "/pumpadur/profile" : `/pumpadur/user-profile/${userId}`}>
            <p className="font-semibold">@{first_name}</p>
          </NavLink>
          <div className="flex text-[12px] font-medium items-center gap-1">
            <p className="text-[#b5a4bf]">{message}</p>
            <div className="flex items-center gap-1">
              <div className="rounded-[2px] bg-white w-4 h-4 min-w-4 min-h-4 ">
                <TokenImage imageSrc={logo} classesName="size-full object-cover " />
              </div>
              <NavLink to={`/pumpadur/tokens/${tokenId}`}>
                <p className="font-semibold text-[#f99239] uppercase">${ticker}</p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <p className="text-[10px] font-medium text-[#95879e]">{getTimeDifference(createdAt)}</p>
    </div>
  );
};

export default LastTransaction;
