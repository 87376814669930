import { useState, useCallback } from "react";
import { clusterApiUrl, Connection, PublicKey } from "@solana/web3.js";
import nacl from "tweetnacl";
import bs58 from "bs58";
import { Buffer } from "buffer";
import { buildUrl } from "../helpers/buildUrl";
import { encryptPayload } from "../helpers/encryptionHelpers";
import { useTelegramApp } from "./useTelegramApp";
import { ACCESS_TOKEN_KEY } from "../constants/auth";
import { useSelector } from "react-redux";
import {
  associatedAccountsSelector,
  currentCountTokensAndPriceSelector,
  currentCountTokensForSellSelector,
  currentTokenSelector,
} from "../store/token/tokensReducer";
import {
  cryptoProfilePublicKeySelector,
  dappPublicKeySelector,
  dappSecretKeySelector,
  sessionKeySelector,
  sharedSecretSelector,
} from "../store/cryptoProfile/cryptoProfileReducer";
import { sellToken } from "../api/solana/sellTokem";
import { buyToken } from "../api/solana/buyToken";
import { createToken } from "../api/solana/createToken";

const NETWORK = clusterApiUrl(import.meta.env.VITE_SOLANA_CLUSTER);
global.Buffer = global.Buffer || Buffer;

const onSignAndSendTransactionRedirectLink = "onSignAndSendTransaction";

export const useTransaction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const connection = new Connection(NETWORK);

  const currentCountTokensAndPrice = useSelector(currentCountTokensAndPriceSelector);
  const associatedAccounts = useSelector(associatedAccountsSelector);
  const currentCountTokensForSell = useSelector(currentCountTokensForSellSelector);
  const token = useSelector(currentTokenSelector);

  const session = useSelector(sessionKeySelector);

  const phantomWalletPublicKeyString = useSelector(cryptoProfilePublicKeySelector);
  let phantomWalletPublicKey: PublicKey | null = phantomWalletPublicKeyString
    ? new PublicKey(phantomWalletPublicKeyString)
    : null;

  const sharedSecretString = useSelector(sharedSecretSelector);
  const sharedSecret = sharedSecretString ? bs58.decode(sharedSecretString) : null;

  const dappSecretKey = useSelector(dappSecretKeySelector);
  const dappPublicKey = useSelector(dappPublicKeySelector);
  const publicKeyDapp = dappPublicKey ? bs58.decode(dappPublicKey) : null;
  const secretKeyDapp = dappSecretKey ? bs58.decode(dappSecretKey) : null;
  const dappKeyPair: nacl.BoxKeyPair | null =
    publicKeyDapp && secretKeyDapp
      ? {
          publicKey: publicKeyDapp,
          secretKey: secretKeyDapp,
        }
      : null;

  const tgApp = useTelegramApp();

  // Підписання та відправка транзакції
  const buyTokenTransaction = useCallback(async () => {
    if (!token) throw new Error("missing token");
    if (!sharedSecret) throw new Error("missing sharedSecret from user");
    if (!phantomWalletPublicKey) throw new Error("missing public key from user");

    const sumForPay = currentCountTokensAndPrice?.sumForPay || 0;

    const fromPubkey = new PublicKey(phantomWalletPublicKey);
    const toPubkey = new PublicKey(token.poolWallet!);
    console.log("toPubkey", token.poolWallet!);
    try {
      setIsLoading(true);
      const transaction = await buyToken(connection, fromPubkey, toPubkey, sumForPay);

      const serializedTransaction = transaction.serialize({
        requireAllSignatures: false,
      });

      const payload = {
        session,
        transaction: bs58.encode(serializedTransaction),
      };

      const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret);

      const token = localStorage.getItem(ACCESS_TOKEN_KEY);

      if (!dappKeyPair) throw new Error("missing dappKeyPair from user");

      const params = new URLSearchParams({
        dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
        nonce: bs58.encode(nonce),
        redirect_link: `${import.meta.env.VITE_PHANTOM_SITE_URL}${onSignAndSendTransactionRedirectLink}?token=${token}trade=buy`,
        payload: bs58.encode(encryptedPayload),
      });

      const url = buildUrl("signTransaction", params, true);
      console.log("url", url);

      tgApp.openLink(url);
      tgApp.close();
    } catch (err) {
      setError(err as Error);
      console.error("Error during sign and send transaction:", err);
    } finally {
      setIsLoading(false);
    }
  }, [currentCountTokensAndPrice, phantomWalletPublicKey]);

  const createTokenTransaction = useCallback(
    async (amount: number) => {
      if (!sharedSecret) throw new Error("missing sharedSecret from user");
      if (!phantomWalletPublicKey) throw new Error("missing public key from user");

      const fromPubkey = new PublicKey(phantomWalletPublicKey);
      const toPubkey = new PublicKey("8JYyjbGs4VZnV3cawHcrWT22b7G2hEcjUawm8cpmCUyD");
      try {
        setIsLoading(true);
        const transaction = await createToken(connection, fromPubkey, toPubkey, amount);

        const serializedTransaction = transaction.serialize({
          requireAllSignatures: false,
        });

        const payload = {
          session,
          transaction: bs58.encode(serializedTransaction),
        };

        const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret);

        const token = localStorage.getItem(ACCESS_TOKEN_KEY);

        if (!dappKeyPair) throw new Error("missing dappKeyPair from user");

        const params = new URLSearchParams({
          dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
          nonce: bs58.encode(nonce),
          redirect_link: `${import.meta.env.VITE_PHANTOM_SITE_URL}${onSignAndSendTransactionRedirectLink}?token=${token}trade=create`,
          payload: bs58.encode(encryptedPayload),
        });

        const url = buildUrl("signTransaction", params, true);
        console.log("url", url);

        tgApp.openLink(url);
        tgApp.close();
      } catch (err) {
        setError(err as Error);
        console.error("Error during sign and send transaction:", err);
      } finally {
        setIsLoading(false);
      }
    },
    [currentCountTokensAndPrice, phantomWalletPublicKey],
  );

  const sellTokenTransaction = useCallback(
    async (amountOfToken: number) => {
      if (!token) throw new Error("missing public key from user");
      if (!associatedAccounts) throw new Error("You have not bought this token yet!");
      if (!sharedSecret) throw new Error("missing sharedSecret from user");
      if (!phantomWalletPublicKey) throw new Error("missing public key from user");

      const fromTokenAccount = new PublicKey(associatedAccounts?.tokenAccount!);
      const toTokenAccount = new PublicKey(token.mintAccount!);

      const userPublicKey = new PublicKey(phantomWalletPublicKey);
      try {
        setIsLoading(true);

        const transaction = await sellToken(connection, fromTokenAccount, toTokenAccount, userPublicKey, amountOfToken);

        const serializedTransaction = transaction.serialize({
          requireAllSignatures: false,
        });

        const payload = {
          session,
          transaction: bs58.encode(serializedTransaction),
        };

        const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret);

        const token = localStorage.getItem(ACCESS_TOKEN_KEY) || "";

        if (!dappKeyPair) throw new Error("missing dappKeyPair from user");

        const params = new URLSearchParams({
          dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
          nonce: bs58.encode(nonce),
          redirect_link: `${import.meta.env.VITE_PHANTOM_SITE_URL}${onSignAndSendTransactionRedirectLink}?token=${token}trade=sell`,
          payload: bs58.encode(encryptedPayload),
        });

        const url = buildUrl("signTransaction", params, true);
        console.log("url", url);

        tgApp.openLink(url);
        tgApp.close();
      } catch (err) {
        setError(err as Error);
        console.error("Error during sign and send transaction:", err);
      } finally {
        setIsLoading(false);
      }
    },
    [associatedAccounts, token, phantomWalletPublicKey, currentCountTokensForSell],
  );

  return {
    isLoading,
    error,
    createTokenTransaction,
    buyTokenTransaction,
    sellTokenTransaction,
  };
};
