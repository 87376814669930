import App from "./App";
import CustomRocketPage from "./pages/rocket/CustomRocketPage";
import AddRocketPage from "./pages/rocket/PickRocketPage";
import RocketPage from "./pages/rocket/RocketPage";
import RoundPage from "./pages/RoundPage";
import RoundsOverviewPage from "./pages/RoundsOverviewPage";
import ClickerPage from "./pages/clicker/index";
import { FriendsPage } from "./pages/FriendsPage.tsx";
import { TasksPage } from "./pages/TasksPage.tsx";
import BuildPortfolioPage from "./pages/portfolio/build";
import PayAndEarnPage from "./pages/onboarding/PayAndEarnPage.tsx";
import HowToEarnPage from "./pages/onboarding/HowToEarnPage.tsx";
import { sentryCreateBrowserRouter } from "./hooks/useSentry.ts";
import FrensEarnings from "./pages/FrensEarnings.tsx";
import DailyEarnings from "./pages/DailyEarnings.tsx";
import SelectionExchangePage from "./pages/SelectionExchangePage.tsx";
import ProfitPerHourPage from "./pages/ProfitPerHourPage.tsx";
import ExchangeRegistrationPage from "./pages/exchange/ExchangeRegistrationPage.tsx";
import { CardsPage } from "./pages/CardsPage.tsx";
// import TapEarnCoinsPage from "./pages/onboarding/TapEarnCoinsPage.tsx";
import EarnPassiveIncome from "./pages/onboarding/EarnPassiveIncome.tsx";
import HourlyIncomePage from "./pages/onboarding/HourlyIncomePage.tsx";
import EarnMoreWithFriendsPage from "./pages/onboarding/EarnMoreWithFriendsPage.tsx";
import ClaimYourAirdropPage from "./pages/onboarding/ClaimYourAirdropPage.tsx";
import OnboardingStakingFinishPage from "./pages/onboarding/OnboardingStakingFinishPage.tsx";
import { LeadersPage } from "./pages/LeadersPage.tsx";
import MainPage from "./pages/pumpadur/main.tsx";
import SettingsPage from "./pages/pumpadur/settings.tsx";
import ProfilePage from "./pages/pumpadur/profile.tsx";
import ConnectWalletPage from "./pages/pumpadur/connectWallet.tsx";
import { DailyRewardPage } from "./pages/DailyRewardPage.tsx";
import { HowToGetTicketPage } from "./pages/HowToGetTicketPage.tsx";
import CreateTokenPage from "./pages/pumpadur/createToken.tsx";
import TokenTradePage from "./pages/pumpadur/tokenTrade.tsx";
import TokenInfoPage from "./pages/pumpadur/tokenInfo.tsx";
import CommunityNotesPage from "./pages/pumpadur/communityNotes.tsx";
import ContentChallengePage from "./pages/ContentChallengePage.tsx";
import UserProfilePage from "./pages/pumpadur/user-profile.tsx";
import WelcomeToPumpadurPage from "./pages/pumpadurOnboarding/welcomeToPupadur.tsx";
import MintAndManageNFT from "./components/Pumpadur/MintAndManageNFT/index.tsx";

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: `/`,
        element: <ClickerPage />,
      },
      {
        path: "/lobby",
        element: <RoundsOverviewPage />,
      },
      {
        path: "/daily-reward",
        element: <DailyRewardPage />,
      },
      {
        path: "rounds/:roundId",
        element: <RoundPage />,
      },
      {
        path: "rounds/:roundId/rockets/:rocketId",
        element: <RocketPage />,
      },
      {
        path: "rounds/:roundId/addRocket",
        element: <AddRocketPage />,
      },
      {
        path: "rounds/:roundId/customRocket",
        element: <CustomRocketPage />,
      },
      {
        path: "rounds/:roundId/customRocket/buildPortfolio",
        element: <BuildPortfolioPage />,
      },

      {
        path: "/friends",
        element: <FriendsPage />,
      },
      {
        path: "/frens-earning",
        element: <FrensEarnings />,
      },
      {
        path: "/daily-earning",
        element: <DailyEarnings />,
      },

      {
        path: "/tasks",
        element: <TasksPage />,
      },
      {
        path: "/content-challenge",
        element: <ContentChallengePage />,
      },
      {
        path: "/cards",
        element: <CardsPage />,
      },
      {
        path: "/how-to-get-ticket",
        element: <HowToGetTicketPage />,
      },

      {
        path: "/selection-exchange",
        element: <SelectionExchangePage />,
      },
      {
        path: "/exchange-registration",
        element: <ExchangeRegistrationPage />,
      },
      // {
      //   path: "/leagues-rewards-page",
      //   element: <LeaguesRewardsPage />,
      // },
      {
        path: "/leagues-rewards-page",
        element: <LeadersPage />,
      },
      {
        path: "/profit-per-hour",
        element: <ProfitPerHourPage />,
      },
{
        path: "/onboarding/pumpadur",
        element: <WelcomeToPumpadurPage />,
      },
      {
        path: "/onboarding/pay-and-earn",
        element: <PayAndEarnPage />,
      },
      {
        path: "/onboarding/how-to-earn",
        element: <HowToEarnPage />,
      },
      {
        path: "/onboarding/tap-and-earn-coins",
        element: <UserProfilePage />,
      },
      {
        path: "/onboarding/earn-passive-income",
        element: <EarnPassiveIncome />,
      },
      {
        path: "/onboarding/hourly-income",
        element: <HourlyIncomePage />,
      },
      {
        path: "/onboarding/earn-more-with-friends",
        element: <EarnMoreWithFriendsPage />,
      },
      {
        path: "/onboarding/claim-airdrop",
        element: <ClaimYourAirdropPage />,
      },
      {
        path: "/onboarding/staking-finish",
        element: <OnboardingStakingFinishPage />,
      },
      // PUMPADUR
      {
        path: "/pumpadur/connectWallet",
        element: <ConnectWalletPage />,

      },
      {
        path: "/pumpadur/mintAndManageNFT",
        element: <MintAndManageNFT />,
      },
      {
        path: "/pumpadur/main",
        element: <MainPage />,
      },
      {
        path: "/pumpadur/create-token",
        element: <CreateTokenPage />,
      },
      {
        path: "/pumpadur/tokens/:tokenId",
        element: <TokenTradePage />,
      },
      {
        path: "/pumpadur/tokens/:tokenId/notes",
        element: <CommunityNotesPage />,
      },
      {
        path: "/pumpadur/tokens/token-info/:tokenId",
        element: <TokenInfoPage />,
      },
      {
        path: "/pumpadur/settings",
        element: <SettingsPage />,
      },
      {
        path: "/pumpadur/profile",
        element: <ProfilePage />,
      },
      {
        path: "/pumpadur/user-profile/:userId",
        element: <UserProfilePage />,
      },
    ],
  },
]);

export default router;
