import { GradientCard } from "../../ui/GradientCard";
import MenuItem from "./MenuItem";
import { mainMenuItems, profileMenuItem } from "./configs/MenuItems";

const MenuList = () => {
  return (
    <div className="pt-4 pb-1 flex flex-col gap-2">
      <GradientCard backgroundColor="#28202C">
        <div className="px-4">
          {profileMenuItem.map(({ icon, id, link, title }) => (
            <MenuItem key={id} icon={icon} id={id} link={link} title={title} border={mainMenuItems.length > 1} />
          ))}
        </div>
      </GradientCard>
    </div>
  );
};

export default MenuList;
