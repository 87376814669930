import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import InputWidthLabel from "../../components/ui/InputWidthLabel";
import TextareaWidthLabel from "../../components/ui/TextareaWidthLabel";
import DarkBackground from "../../components/ui/DarkBackground";
import TokenImageNFTChoose from "../../components/Pumpadur/Token/TokenImageNFTChoose";
import BottomButton from "../../components/ui/BottomButton";
import { ConfirmCreateToken } from "../../components/Pumpadur/Token/ConfirmCreateToken";
import { saveDataTokenForCreate } from "../../api/tokens/saveDataTokenForCreate";
import { getFeeForCreateToken } from "../../api/tokens/getFeeForCreateToken";
import { FeeData } from "../../api/tokens/types";
import { useVisualViewport } from "../../hooks/useVisualViewport";
import { useToastNotifications } from "../../hooks/useToastNotifications";
import { ToastNotificationType } from "../../store/toastNotifications/toastNotificationsReducer";

const telegramPlaceholder = "https://t.me/";
const twitterPlaceholder = "https://x.com/";

const CreateTokenPage = () => {
  const dispatch = useAppDispatch();

  const [isOpenedPopup, setIsOpenedPopup] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [ticker, setTicker] = useState<string>("");
  const [storyOfMeme, setStoryOfMeme] = useState<string>("");
  const [telegramChannel, setTelegramChannel] = useState<string>(telegramPlaceholder);
  const [telegramChat, setTelegramChat] = useState<string>(telegramPlaceholder);
  const [twitter, setTwitter] = useState<string>(twitterPlaceholder);
  const [tokenImage, setTokenImage] = useState<File | null | Blob>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [fee, setFee] = useState<FeeData | null>(null);
  const [positionBottom, setPositionBottom] = useState<string>("fixed  left-0 right-0 bottom-0");
  const [containerBackground, setContainerBackground] = useState<string>(
    "linear-gradient(0deg, #482D59 0%, rgba(72, 45, 89, 0.8) 59.93%, rgba(72, 45, 89, 0) 100%)",
  );

  const { height: heightVisualViewport } = useVisualViewport();
  const { showNotification } = useToastNotifications();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    validateForm();
  }, [name, ticker, storyOfMeme, telegramChannel, telegramChat, twitter, tokenImage]);

  const handleChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
    };

  const validateForm = () => {
    const newErrors: any = {};

    if (!name) newErrors.name = "Name is required";
    if (!ticker) newErrors.ticker = "Ticker is required";
    if (!storyOfMeme) newErrors.storyOfMeme = "Story of Meme is required";
    if (!tokenImage) newErrors.tokenImage = "Token image is required";

    const telegramUrlPattern = /^https:\/\/t\.me\//;

    if (!telegramChannel) {
      newErrors.telegramChannel = "Telegram channel is required";
    } else if (!telegramUrlPattern.test(telegramChannel)) {
      newErrors.telegramChannel = "Telegram channel must start with https://t.me/";
    }

    if (!telegramChat) {
      newErrors.telegramChat = "Telegram chat is required";
    } else if (!telegramUrlPattern.test(telegramChat)) {
      newErrors.telegramChat = "Telegram chat must start with https://t.me/";
    }

    if (!twitter) {
      newErrors.twitter = "Twitter handle is required";
    } else if (!/^https:\/\/x\.com\/.+/.test(twitter)) {
      newErrors.twitter = "Twitter handle must be a valid URL starting with https://twitter.com/";
    }

    setIsDisabled(Object.keys(newErrors).length > 0);
  };

  const saveToken = async (data: FormData) => {
    return await saveDataTokenForCreate(data);
  };

  const handlePostToken = async (): Promise<boolean> => {
    if (!isDisabled) {
      const formData = new FormData();

      if (tokenImage) {
        formData.append("logo", tokenImage);
      }
      formData.append("name", name);
      formData.append("ticker", ticker);
      formData.append("description", storyOfMeme);
      formData.append("tgChatLink", telegramChat);
      formData.append("tgChannelLink", telegramChannel);
      formData.append("xTwitter", twitter);
      formData.append("nftMintAddress", "");

      try {
        const result = await saveToken(formData);
        console.log("Transaction successfully completed");
        if (!result?.data?.data?.success) {
          showNotification({
            title: "Transaction failed. Please, try again later!",
            type: ToastNotificationType.Error,
            timeoutMs: 2000,
          });
          return false;
        }
      } catch (err) {
        console.error("Error signing and sending transaction:", err);
        showNotification({
          title: "Transaction failed. Please, try again later!",
          type: ToastNotificationType.Error,
          timeoutMs: 2000,
        });
        return false;
      } finally {
        setIsPending(false);
      }
      return true;
    }
    return false;
  };

  const handleContinue = async () => {
    setIsPending(true);
    try {
      const result = await getFeeForCreateToken();
      setIsOpenedPopup(true);
      setFee(result);
    } catch (error) {
      console.error("Error fetching fee:", error);
    } finally {
      setIsPending(false);
    }
  };

  const handleTelegramChannelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (!newValue.startsWith(telegramPlaceholder)) {
      setTelegramChannel(telegramPlaceholder);
    } else {
      setTelegramChannel(newValue);
    }
  };

  const handleTelegramChatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (!newValue.startsWith(telegramPlaceholder)) {
      setTelegramChat(telegramPlaceholder);
    } else {
      setTelegramChat(newValue);
    }
  };

  const handleTwitterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (!newValue.startsWith(twitterPlaceholder)) {
      setTwitter(twitterPlaceholder);
    } else {
      setTwitter(newValue);
    }
  };

  useEffect(() => {
    const createTokenContainer = document.querySelector(".create-token-container") as HTMLElement;
    const mainContainer = document.querySelector(".main-container") as HTMLElement;

    if (isIOS) {
      if (heightVisualViewport < window.innerHeight) {
        createTokenContainer.style.paddingBottom = "0px";
        const currentPaddingBottom = window.innerHeight - heightVisualViewport;
        mainContainer.style.paddingBottom = `${currentPaddingBottom}px`;
        setContainerBackground("");
        setPositionBottom("relative -left-4 -right-4 w-[110%]");
      } else {
        createTokenContainer.style.paddingBottom = "110px";
        setContainerBackground(
          "linear-gradient(0deg, #482D59 0%, rgba(72, 45, 89, 0.8) 59.93%, rgba(72, 45, 89, 0) 100%)",
        );
        setPositionBottom("fixed  left-0 right-0 bottom-0");
      }
    }
    return () => {
      if (isIOS) createTokenContainer.style.paddingBottom = "0px";
    };
  }, [heightVisualViewport]);

  return (
    <div className="pb-[110px] pt-[3vh] create-token-container">
      <DarkBackground>
        <TokenImageNFTChoose selectedTokenImage={tokenImage} setSelectedTokenImage={setTokenImage} />
      </DarkBackground>
      <form className="flex flex-col gap-2 pt-6">
        <div className="rounded-2xl bg-mediumGrey/45">
          <div className="px-4">
            <InputWidthLabel label="Name" border={true} value={name} handleChange={handleChange(setName)} />
            <InputWidthLabel label="Ticker" value={ticker} handleChange={handleChange(setTicker)} />
          </div>
        </div>

        <TextareaWidthLabel
          label="Story of Meme"
          maxLength={1000}
          value={storyOfMeme}
          handleChange={(e) => setStoryOfMeme(e.target.value)}
        />

        <div className="rounded-2xl bg-mediumGrey/45 min-h-[160px]">
          <div className="px-4">
            <InputWidthLabel
              label="Telegram channel"
              border={true}
              value={telegramChannel}
              handleChange={handleTelegramChannelChange}
            />
            <InputWidthLabel
              label="Telegram chat"
              border={true}
              value={telegramChat}
              handleChange={handleTelegramChatChange}
            />
            <InputWidthLabel label="X (Twitter)" value={twitter} handleChange={handleTwitterChange} />
          </div>
        </div>
      </form>
      <BottomButton
        text="Continue"
        handler={handleContinue}
        attributes={{
          disabled: isDisabled,
        }}
        isPending={isPending}
        position={positionBottom}
        containerBackground={containerBackground}
        className={isDisabled ? "text-white/50" : "text-white"}
        bgColor={isDisabled ? "#563c66" : "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"}
      />
      <ConfirmCreateToken
        fee={fee}
        isOpened={isOpenedPopup}
        handleConfirm={handlePostToken}
        setIsOpened={setIsOpenedPopup}
      />
    </div>
  );
};

export default CreateTokenPage;
