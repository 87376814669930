import { useSelector } from "react-redux";
import { moneyFormat } from "../../../../helpers/moneyFormat";
import ProgressLine from "../../../Common/ProgressLine";
import { marcetCapSelector, socketConnectSelector, tokenProgressSelector } from "../../../../store/token/tokensReducer";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../store/hooks";
import { fetchTokenProgress } from "../../../../store/token/actions/fetchTokenInfoAction";
// import { connectToSocket } from "../../../../helpers/connectSocket";

const TokenMetrics = () => {
  const progress = useSelector(tokenProgressSelector);
  const marcetCap = useSelector(marcetCapSelector);
  const socket = useSelector(socketConnectSelector);
  const { tokenId } = useParams();
  const dispatch = useAppDispatch();
    // Обработка обновления токена через сокет

    useEffect(() => {
      if (!socket) return;
  
      const handleTokenUpdated = (update: { tokenId: number }) => {
        console.log("Token updated:", update);
        dispatch(fetchTokenProgress(tokenId!));
      };
  
      socket.on("token-updated", handleTokenUpdated);
  
      return () => {
        socket.off("token-updated", handleTokenUpdated);
      };
    }, [socket, tokenId, dispatch]);
  
  const goal = 25000;

  return (
    <div>
      <div className="flex justify-between font-semibold pb-[7px] pt-5">
        <div>
          <span className="text-lavenderGray">Market Cap:</span>{" "}
          <span className="text-aquaMint">${moneyFormat(+marcetCap, 0)}</span>
        </div>
        <div>
          <span className="text-lavenderGray">Goal:</span> <span className="text-aquaMint">${moneyFormat(goal)}</span>
        </div>
      </div>
      <ProgressLine progress={+progress} styles="bg-white/25" />
    </div>
  );
};

export default TokenMetrics;
