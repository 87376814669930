import { useEffect, useState } from "react";
import { TradeType, setBackgroundTradeType } from "../FilterTrade";
import { GraphData, PercentChangeData, StatiscticsData } from "../../../../../api/tokens/types";
import { getHistoricalTokenStatistics } from "../../../../../api/tokens/getHistoricalTokenStatistics";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { socketConnectSelector } from "../../../../../store/token/tokensReducer";

interface Item {
  totalTitle: string;
  buyTitle: string;
  sellTitle: string;
  totalValue: number;
  buyValue: number;
  sellValue: number;
}

const TransactionSummary = () => {
  const [data, setData] = useState<Item[]>();
  const [isReloadStatistics, setIsReloadStatistics] = useState<boolean>(true);
  const { tokenId } = useParams();
  const [selectedPeriod, setSelectedPeriod] = useState<PercentChangeData | null>({ overallChange: "0", period: 1440 });
  const [percentageChanges, setPercentageChanges] = useState<PercentChangeData[] | null>(null);
  const socket = useSelector(socketConnectSelector);
  const transactionHeaders = [
    { totalTitle: "TXNS", buyTitle: "BUYS", sellTitle: "SELLS" },
    { totalTitle: "VOLUME", buyTitle: "BUY VOL", sellTitle: "SELL VOL" },
    { totalTitle: "MAKERS", buyTitle: "BUYERS", sellTitle: "SELLERS" },
  ];

  const fetchStatisticsData = (data: GraphData) => {
    getHistoricalTokenStatistics(data).then((data) => {
      const statisticsData = formatToStatisticsData(data.result);
      setPercentageChanges(data.percentChanges);

      setData(statisticsData);
    });
  };
  
    useEffect(() => {
      if (!socket) return;
  
      const handleTokenUpdated = (update: { tokenId: number }) => {
        console.log("Token updated:", update);
        setIsReloadStatistics(true);
      };
  
      socket.on("token-updated", handleTokenUpdated);
  
      return () => {
        socket.off("token-updated", handleTokenUpdated);
      };
    }, [socket]);

  useEffect(() => {
    if (!isReloadStatistics) {
      return;
    }
    const data: GraphData = { tokenId: +tokenId!, time: convertMinutesToHours(+(selectedPeriod?.period || 0)) };
    fetchStatisticsData(data);
    setIsReloadStatistics(false);
  }, [isReloadStatistics]);

  const changePeriod = (percentChangeData: PercentChangeData) => {
    console.log("changePeriod", percentChangeData);
    setSelectedPeriod(percentChangeData);
    const data: GraphData = { tokenId: +tokenId!, time: convertMinutesToHours(+percentChangeData.period) };
    fetchStatisticsData(data);
  };

  const formatToStatisticsData = (apiData: StatiscticsData): Item[] => {
    return [
      {
        ...transactionHeaders[0], // TXNS
        totalValue: apiData.txns,
        buyValue: apiData.buy,
        sellValue: apiData.sell,
      },
      {
        ...transactionHeaders[1], // VOLUME
        totalValue: apiData.volume,
        buyValue: apiData.buyvol,
        sellValue: apiData.sellvol,
      },
      {
        ...transactionHeaders[2], // MAKERS
        totalValue: apiData.makers,
        buyValue: apiData.buyers,
        sellValue: apiData.sellers,
      },
    ];
  };

  return (
    <>
      <PercentageChangeBlock changes={percentageChanges} changePeriod={changePeriod} activePeriod={selectedPeriod} />
      <div className="pt-[14px]">{data && data.map((item, index) => <Item item={item} key={index} />)}</div>
    </>
  );
};

const Item = ({ item }: { item: Item }) => {
  const { buyTitle, buyValue, sellTitle, sellValue, totalTitle, totalValue } = item;

  return (
    <div className="flex gap-[7vw] leading-none text-[12px] py-4 border-solid border-b-[2px] border-white/10">
      <div className="flex flex-col gap-[6px] w-[52px] min-w-[52px] ">
        <p className="text-lavenderGray font-semibold">{totalTitle}</p>
        <p className="font-bold">{Math.round(totalValue)}</p>
      </div>
      <div className="w-full flex flex-col items-stretch">
        <div className="flex justify-between mb-2">
          <div>
            <span className="text-lavenderGray font-semibold pr-1">{buyTitle}</span>
            <span className="font-bold">{Math.round(buyValue)}</span>
          </div>
          <div>
            <span className="text-lavenderGray font-semibold pr-1">{sellTitle}</span>
            <span className="font-bold">{Math.round(sellValue)}</span>
          </div>
        </div>
        <div>
          <ProgressLine countBuys={buyValue} countSells={sellValue} />
        </div>
      </div>
    </div>
  );
};

const ProgressLine = ({ countBuys, countSells }: { countBuys: number; countSells: number }) => {
  const amount = countBuys + countSells;
  const buysPercentage = countBuys > 0 ? (countBuys * 100) / amount : 0;
  const sellsPercentage = countSells > 0 ? (countSells * 100) / amount : 0;
  return (
    <div className={`flex gap-[1px] h-[8px] rounded-full w-full   p-[2px] bg-[#FFFFFF40]  items-center`}>
      <div className={`relative h-[6px] w-full rounded-full  flex`}>
        <div
          className="rounded-l-full h-[6px]"
          style={{
            width: `${buysPercentage}%`,
            background: setBackgroundTradeType(TradeType.BUY),
          }}
        ></div>
        <div
          className="rounded-r-full h-[6px]"
          style={{
            width: `${sellsPercentage}%`,
            background: setBackgroundTradeType(TradeType.SELL),
          }}
        ></div>
      </div>
    </div>
  );
};

interface PercentageChangeBlockProps {
  changes: PercentChangeData[] | null;
  changePeriod: (period: PercentChangeData) => void;
  activePeriod: PercentChangeData | null;
}

const adjustChanges = (changes: PercentChangeData[] | null): PercentChangeData[] => {
  if (!changes) {
    return [];
  }

  let fallbackChange = "0";

  return changes.map((change) => {
    if (parseFloat(change.overallChange) !== 0) {
      fallbackChange = change.overallChange;
    } else {
      change.overallChange = fallbackChange;
    }
    return change;
  });
};

const PercentageChangeBlock: React.FC<PercentageChangeBlockProps> = ({ changes, changePeriod, activePeriod }) => {
  // Преобразование массива изменений
  const adjustedChanges = adjustChanges(changes);

  const getColor = (overallChange: string) => {
    const changeValue = parseFloat(overallChange);
    if (changeValue >= 0) return "text-seaGreen";
    if (changeValue < 0) return "text-coralRed";
    return "";
  };

  return (
    <>
      {adjustedChanges.length > 0 && (
        <div className="w-full rounded-2xl bg-black/25 uppercase mt-3 py-3 leading-none">
          <ul className="flex justify-center items-center text-center">
            {adjustedChanges.map(({ period, overallChange }, index) => (
              <div className="relative" key={period}>
                <li
                  onClick={() => {
                    changePeriod({ period, overallChange });
                  }}
                  className={`relative z-20 flex flex-col justify-between gap-[14px] px-[4.5vw] ${
                    index < adjustedChanges.length - 1 ? "border-solid border-r-[2px] border-white/10 " : ""
                  } `}
                >
                  <span className="font-semibold text-[12px]">{convertMinutesToHoursString(period)}</span>
                  <span className={`${getColor(overallChange)} font-bold`}>
                    {parseFloat(overallChange).toFixed(2)}%
                  </span>
                </li>
                {activePeriod?.period === period && (
                  <p className="size-full -top-2 left-[0.8vw] h-[140%] w-[90%] rounded-xl absolute bg-white/20 z-0"></p>
                )}
              </div>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default TransactionSummary;

function convertMinutesToHours(minutes: number): number {
  return minutes / 60;
}

function convertMinutesToHoursString(minutes: number): string {
  if (minutes > 59) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes > 0 ? `${hours}h ${remainingMinutes}m` : `${hours}h`;
  } else {
    return `${minutes}m`;
  }
}
