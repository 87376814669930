import welcomeToPumpadur from "../assets/img/welcomeToPumpadur.png";
import createYourMemeToken from "../assets/img/createYourMemeToken.png";
import safetyFirst from "../assets/img/safetyFirst.png";
import tradeInstantly from "../assets/img/tradeInstantly.png";
import joinTheMeme from "../assets/img/joinTheMeme.png";

const imageCache: { [key: string]: HTMLImageElement } = {};

export const preloadImages = async (): Promise<void> => {
    const imgs = [welcomeToPumpadur, createYourMemeToken, safetyFirst, tradeInstantly, joinTheMeme];
  for (const url of imgs) {
    await new Promise<void>((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        imageCache[url] = img;
        resolve();
      };
    });
  }
};

export const getCachedImage = (url: string): HTMLImageElement | undefined => {
  return imageCache[url];
};
